import React, { useState } from 'react';
import {
  Row, Col, Card, Button,
  Table, Modal, Form
} from 'react-bootstrap';
import {
  File, Upload, Download,
  CheckCircle, XCircle, AlertCircle,
  Truck, MapPin, Clock
} from 'react-feather';
import {
  Clock as TimelineIcon
} from 'lucide-react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps';

// Documents Component
const ShipmentDocuments = ({ shipmentDetails }) => {
  console.log("ShipmentDetails: ", shipmentDetails)
  const [showUploadModal, setShowUploadModal] = useState(false);

  // Mock document types
  const documentTypes = [
    { name: 'Bill of Lading', required: true, uploaded: true },
    { name: 'Proof of Delivery', required: true, uploaded: false },
    { name: 'Invoice', required: false, uploaded: true },
    { name: 'Inspection Report', required: false, uploaded: false }
  ];

  const getStatusIcon = (uploaded, required) => {
    if (uploaded) return <CheckCircle size={20} className="text-success" />;
    if (required) return <AlertCircle size={20} className="text-warning" />;
    return <XCircle size={20} className="text-muted" />;
  };

  return (
    <Row>
      <Col md={8}>
        <Card className="shadow-sm mb-4">
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="card-title">
                <File size={20} className="me-2" /> Shipment Documents
              </h5>
              <Button
                variant="primary"
                size="sm"
                onClick={() => setShowUploadModal(true)}
              >
                <Upload size={16} className="me-1" /> Upload Document
              </Button>
            </div>

            <Table hover>
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {documentTypes.map((doc, index) => (
                  <tr key={index}>
                    <td>
                      {doc.name}
                      {doc.required && <span className="text-danger ms-1">*</span>}
                    </td>
                    <td>
                      {getStatusIcon(doc.uploaded, doc.required)}
                      {doc.uploaded ? 'Uploaded' : 'Pending'}
                    </td>
                    <td>
                      {doc.uploaded && (
                        <Button variant="outline-primary" size="sm" className="me-2">
                          <Download size={16} className="me-1" /> Download
                        </Button>
                      )}
                      {!doc.uploaded && (
                        <Button variant="outline-warning" size="sm">
                          <Upload size={16} className="me-1" /> Upload
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>

      <Col md={4}>
        <Card className="shadow-sm">
          <Card.Body>
            <h5 className="card-title mb-3">
              <AlertCircle size={20} className="me-2" /> Document Requirements
            </h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <CheckCircle size={16} className="text-success me-2" />
                Bill of Lading
              </li>
              <li className="mb-2">
                <AlertCircle size={16} className="text-warning me-2" />
                Proof of Delivery Pending
              </li>
              <li>
                <XCircle size={16} className="text-muted me-2" />
                Additional Inspection Report
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Col>

      {/* Upload Document Modal */}
      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Document Type</Form.Label>
              <Form.Select>
                <option>Select Document Type</option>
                <option>Bill of Lading</option>
                <option>Proof of Delivery</option>
                <option>Invoice</option>
                <option>Inspection Report</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Upload File</Form.Label>
              <Form.Control type="file" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Row>
  );
};

// Tracking Component
const ShipmentTracking = ({ shipmentDetails }) => {
  // Mock tracking events
  const trackingEvents = [
    {
      type: 'Pickup',
      location: shipmentDetails.origin?.companyName,
      address: shipmentDetails.origin?.address,
      status: 'Completed',
      timestamp: new Date(shipmentDetails.origin?.windowStart)
    },
    {
      type: 'En Route',
      location: 'Interstate 40',
      address: 'Somewhere in Texas',
      status: 'In Progress',
      timestamp: new Date()
    },
    {
      type: 'Stop',
      location: shipmentDetails.stop?.companyName,
      address: shipmentDetails.stop?.address,
      status: 'Pending',
      timestamp: new Date(shipmentDetails.stop?.windowStart)
    },
    {
      type: 'Delivery',
      location: shipmentDetails.destination?.companyName,
      address: shipmentDetails.destination?.address,
      status: 'Not Started',
      timestamp: null
    }
  ];
  const styles = {
    timeline: {
      position: 'relative',
      paddingLeft: '20px',
      // borderLeft: '2px solid #e0e0e0'
    },
    timelineItem: {
      position: 'relative',
      paddingBottom: '20px',
      paddingLeft: '30px'
    },
    timelineIcon: {
      position: 'absolute',
      left: '-25px',
      top: '0',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      backgroundColor: '#f0f0f0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    activeItem: {
      borderLeft: '3px solid #007bff'
    }
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed': return 'success';
      case 'In Progress': return 'warning';
      case 'Pending': return 'info';
      default: return 'secondary';
    }
  };

  const markers = [
    {
      markerOffset: -15,
      name: "Origin",
      coordinates: [-97.7431, 30.2672] // Austin, TX coordinates
    },
    {
      markerOffset: 25,
      name: "Current",
      coordinates: [-99.9018, 31.9686] // Somewhere in Texas
    },
    {
      markerOffset: -15,
      name: "Destination",
      coordinates: [-96.7970, 32.7767] // Dallas, TX coordinates
    }
  ];

  return (
    <div className="container-fluid p-4">
      <Row className="g-4">
      <Col lg={12}>
          <Card className="h-100 shadow-sm">
            <Card.Body className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Truck size={20} className="me-2" />
                <h5 className="card-title mb-0">Shipment Details</h5>
              </div>

              <Table borderless className="flex-grow-1">
                <tbody>
                  <tr>
                    <td className="text-muted">Current Status:</td>
                    <td>
                      <span className="badge bg-warning">
                        En Route
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">Estimated Delivery:</td>
                    <td>
                      {new Date(shipmentDetails.stop?.windowEnd).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">Days in Transit:</td>
                    <td>2 days</td>
                  </tr>
                  <tr>
                    <td className="text-muted">Current Location:</td>
                    <td>Interstate 40, Texas</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          <Card className="h-100">
            <Card.Body className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Clock size={20} className="me-2" />
                <h5 className="card-title mb-0">Shipment Timeline</h5>
              </div>
              <div style={styles.timeline} className="">
                {trackingEvents.map((event, index) => (
                  <div
                    key={index}
                    style={{
                      ...styles.timelineItem,
                      ...(event.status === 'In Progress' ? styles.activeItem : {})
                    }}
                  >
                    <div style={styles.timelineIcon}>
                      {event.type === 'Pickup' && <Truck size={16} />}
                      {event.type === 'En Route' && <MapPin size={16} />}
                      {event.type === 'Stop' && <Clock size={16} />}
                      {event.type === 'Delivery' && <Truck size={16} />}
                    </div>
                    <div>
                      <h6 className="mb-1">
                        {event.type}
                        <span
                          className={`badge bg-${getStatusColor(event.status)} ms-2`}
                        >
                          {event.status}
                        </span>
                      </h6>
                      <p className="text-muted mb-1">{event.location}</p>
                      <p className="text-muted mb-1">{event.address}</p>
                      {event.timestamp && (
                        <small className="text-muted">
                          {event.timestamp.toLocaleString()}
                        </small>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        </Col>



        <Col lg={12}>
          <Card className="shadow-sm">
            <Card.Body>
              <div className="d-flex align-items-center mb-3">
                <MapPin size={20} className="me-2" />
                <h5 className="card-title mb-0">Shipment Route</h5>
              </div>
              <div className="map-container" style={{width: '100%', height: '400px' }}>
                <ComposableMap
                  projection="geoAlbersUsa"
                  projectionConfig={{
                    scale: 1000,
                    center: [-97, 31]
                  }}
                  width={800}
                  height={400}
                  style={ {backgroundImage:"https://assets.science.nasa.gov/content/dam/science/esd/eo/content-feature/colorimage/images/warsaw_oli_2013251.jpg",  width: '100%', height: 'auto', }}
                >
                  <Geographies geography="/features.json">
                    {({ geographies }) =>
                      geographies.map(geo => (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill="#EAEAEC"
                          stroke="#D6D6DA"
                        />
                      ))
                    }
                  </Geographies>
                  {markers.map(({ name, coordinates, markerOffset }) => (
                    <Marker key={name} coordinates={coordinates}>
                      <circle r={10} fill={
                        name === "Origin" ? "#28a745" :
                          name === "Current" ? "#ffc107" :
                            "#dc3545"
                      } stroke="#fff" strokeWidth={2} />
                      <text
                        textAnchor="middle"
                        y={markerOffset}
                        style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                      >
                        {name}
                      </text>
                    </Marker>
                  ))}
                </ComposableMap>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


export { ShipmentDocuments, ShipmentTracking };