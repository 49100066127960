import { Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Facebook, Mail, MessageSquare, Phone, PhoneCall, Star } from 'react-feather'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ExportLeads from '../../../components/modelpopup/Crm/ExportLeads'
import DeleteContact from '../../../components/modelpopup/Crm/DeleteContact'
import AddContact from '../../../components/modelpopup/Crm/AddContact'
import EditContact from '../../../components/modelpopup/Crm/EditContact'
import AddNotes from '../../../components/modelpopup/Crm/AddNotes'
import { ENDPOINT } from '../../../Config/Constants';
import axios from 'axios'
import {useNavigate} from 'react-router-dom'


const CustomerListPage = () => {

  const navigate = useNavigate();
  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState();
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [inputValue2, setInputValue2] = useState("");
  const [focused2, setFocused2] = useState(false);
  const [inputValue1, setInputValue1] = useState("");
  const [focused1, setFocused1] = useState(false);
  const [sortOption, setSortOption] = useState("Sort By Alphabet");
  const [selectedCountry, setSelectedCountry] = useState("--Select--");





  const transformCustomers = (apiCustomers) => {
    if (!apiCustomers || apiCustomers.length === 0) return [];
    return apiCustomers.map((customer, index) => ({
      sno: index + 1,
      id: customer.customer_id || `${index}`, // Retain existing key
      company: customer.customer_company_name || 'Unknown Company', // Retain existing key
      name: customer.customer_primary_contact_name || 'No Contact', // Retain existing key
      email: customer.customer_primary_contact_email || 'N/A', // Retain existing key
      type: customer.customer_type || 'Unspecified', // Retain existing key
      phone: customer.customer_primary_contact_phone || 'N/A', // Retain existing key
      address: customer.customer_billing_address
        ? `${customer.customer_billing_address.street || ''}, ${customer.customer_billing_address.city || ''}, ${customer.customer_billing_address.state || ''}`
        : 'No Address', // Retain existing key
      contractStatus: customer.customer_contract_status ? 'Active' : 'Inactive', // Retain existing key
      preferredCommMethod: customer.customer_preferred_comm_method || 'Not Specified', // Retain existing key
      totalPayments: customer.customer_payment_history
        ? customer.customer_payment_history.reduce((sum, payment) => sum + (payment.amount || 0), 0)
        : 0, // Retain existing key
      lastPaymentDate: customer.customer_payment_history?.length
        ? customer.customer_payment_history[customer.customer_payment_history.length - 1]?.date || 'N/A'
        : 'N/A', // Retain existing key
      image_url: customer.image_url || "https://marketplace.canva.com/EAFaFUz4aKo/2/0/1600w/canva-yellow-abstract-cooking-free-logo-JmYWTjUsE-Q.jpg", // Retain existing key
      dotNumber: customer.customer_dot_number || 'N/A',
      mcNumber: customer.customer_mc_number || 'N/A',
      billingContactName: customer.customer_billing_contact || 'N/A',
      billingContactPhone: customer.customer_billing_contact_phone || 'N/A',
      billingContactEmail: customer.customer_billing_contact_email || 'N/A',
      billingAddress: customer.customer_billing_address
        ? {
          street: customer.customer_billing_address.street || '',
          city: customer.customer_billing_address.city || '',
          state: customer.customer_billing_address.state || '',
          zip: customer.customer_billing_address.zip || '',
          country: customer.customer_billing_address.country || ''
        }
        : { street: '', city: '', state: '', zip: '', country: '' },
      taxId: customer.customer_tax_id || 'N/A',
      industry: {
        industrySector: customer.customer_industry?.sector || 'Unspecified',
        industrySpecialization: customer.customer_industry?.specialization || 'Unspecified',
      },
      industrySector: customer.customer_industry?.sector || 'Not specified',
      // industrySpecialization: customer.customer_industry?.specialization || 'Unspecified',
      // paymentTerms: customer.customer_payment_terms || 'Not Specified',
      paymentHistory: customer.customer_payment_history || []
    }));

  };


  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${ENDPOINT}/api/customers`);
        const transformedCustomers = transformCustomers(response.data);
        setCustomers(transformedCustomers);
      } catch (err) {
        setError('Failed to fetch customers: ' + (err.response?.data?.message || err.message));
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomers();
  }, [error]);


  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener('click', handleClick);

    return () => {
      maximizeBtn.removeEventListener('click', handleClick);
      cleanup();
    };
  }, [isFullScreen]);



  const handleLabelClick = () => {
    setFocused(true);
  };
  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };
  //
  const handleLabelClick1 = () => {
    setFocused1(true);
  };
  const handleInputBlur1 = () => {
    if (inputValue1 === "") {
      setFocused1(false);
    }
  };
  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setInputValue1(value);
    if (value !== "" && !focused1) {
      setFocused1(true);
    }
  };

  const handleLabelClick2 = () => {
    setFocused1(true);
  };
  const handleInputBlur2 = () => {
    if (inputValue2 === "") {
      setFocused2(false);
    }
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue2(value);
    if (value !== "" && !focused2) {
      setFocused2(true);
    }
  };
  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };
  const sortoption = [
    { value: 'Sort By Alphabet', label: 'Sort By Alphabet' },
    { value: 'Ascending', label: 'Ascending' },
    { value: 'Descending', label: 'Descending' },
    { value: 'Recently Viewed', label: 'Recently Viewed' },
    { value: 'Recently Added', label: 'Recently Added' },
  ];
  const countrylist = [
    { value: '--Select--', label: '--Select--' },
    { value: 'Germany', label: 'Germany' },
    { value: 'USA', label: 'USA' },
    { value: 'India', label: 'India' },
    { value: 'China', label: 'China' },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  //filter
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const columns = [
    {
      title: "Logo",
      dataIndex: "image_url",
      render: (logoUrl) => (
        <div style={{width: "40px", height: "40px"}}>
          <img
            src={logoUrl}
            alt="Company Logo"
            style={{ width: "100%", height: "100%", objectFit: "contain",borderRadius: 50 }}
          />
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "company",
      render: (text) => <strong>{text}</strong>,
      sorter: (a, b) => a.company.localeCompare(b.company),
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      render: (_, record) => (
        <span>
          {record.industrySector}<br/>{record.industrySpecialization}
        </span>
      ),
    },
    {
      title: "DOT Number",
      dataIndex: "dotNumber",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.dotNumber.localeCompare(b.dotNumber),
    },

    {
      title: "Primary Contact",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          <strong>{text}</strong>
        </div>
      ),
    },
    {
      title: "Payment Terms",
      dataIndex: "paymentTerms",
      render: (text) => <span>{text || "Net 35"}</span>,
    },
    {
      title: "Contract Status",
      dataIndex: "contractStatus",
      render: (status) => (
        <span className={status === "Active" ? "badge badge-success" : "badge badge-danger"}>
          {status}
        </span>
      ),
    },
  ];


  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Customers List</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Customers List</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    <Link to="#" className="grid-view btn btn-link">
                      <i className="las la-redo-alt" />
                    </Link>

                    <Link to="#" className="list-view btn btn-link" id="collapse-header" ref={maximizeBtnRef}>

                      <i className="las la-expand-arrows-alt" />
                    </Link>

                    <Link
                      to="#"
                      className={`list-view btn btn-link ${isFilterVisible ? "active-filter" : ""}`}
                      id="filter_search" onClick={toggleFilterVisibility}
                    >
                      <i className="las la-filter" />
                    </Link>
                  </div>
                  <div className="form-sort">
                    <Link
                      to="#"
                      className="list-view btn btn-link"
                      data-bs-toggle="modal"
                      data-bs-target="#export"
                    >
                      <i className="las la-file-export" />
                      Export
                    </Link>
                  </div>
                  <Link
                    to="#"
                    className="btn add-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#add_contact"
                  >
                    <i className="la la-plus-circle" /> Add Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <div
            className={`filter-filelds${isFilterVisible ? ' visible' : ''}`}
            id="filter_inputs"
            style={{ display: isFilterVisible ? 'block' : 'none' }}
          >
            <div className="row filter-row">
              <div className="col-xl-2">
                <div
                  className={
                    focused || inputValue !== ""
                      ? "input-block mb-3 form-focus focused"
                      : "input-block mb-3 form-focus"
                  }
                >
                  <input
                    type="text"
                    className="form-control floating"
                    value={inputValue}
                    onFocus={handleLabelClick}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                  />
                  <label className="focus-label">Contact Name</label>
                </div>
              </div>
              <div className="col-xl-2">
                <div
                  className={
                    focused2 || inputValue2 !== ""
                      ? "input-block mb-3 form-focus focused"
                      : "input-block mb-3 form-focus"
                  }
                >
                  <input
                    type="text"
                    className="form-control floating"
                    value={inputValue2}
                    onFocus={handleLabelClick2}
                    onBlur={handleInputBlur2}
                    onChange={handleInputChange2}
                  />
                  <label className="focus-label" >Email</label>
                </div>
              </div>
              <div className="col-xl-2">
                <div
                  className={
                    focused1 || inputValue1 !== ""
                      ? "input-block mb-3 form-focus focused"
                      : "input-block mb-3 form-focus"
                  }
                >
                  <input
                    type="text"
                    className="form-control floating"
                    value={inputValue1}
                    onFocus={handleLabelClick1}
                    onBlur={handleInputBlur1}
                    onChange={handleInputChange1}
                  />
                  <label className="focus-label" onClick={handleLabelClick1}>Phone Number</label>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus focused">
                  <DateRangePicker initialSettings={initialSettings}>
                    <input
                      className="form-control  date-range bookingrange"
                      type="text"
                    />
                  </DateRangePicker>
                  <label className="focus-label">From - To Date</label>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus select-focus">

                  <Select
                    options={countrylist}
                    placeholder="--Select--"
                    styles={customStyles}
                  />
                  <label className="focus-label">Location</label>
                </div>
              </div>
              <div className="col-xl-2">
                <Link to="#" className="btn btn-success w-100">
                  {" "}
                  Search{" "}
                </Link>
              </div>
            </div>
          </div>
          <hr />
          {/* /Search Filter */}
          <div className="filter-section">
            <ul>
              <li>
                <div className="view-icons">
                  {/* <Link
                    to="/contact-list"
                    className="list-view btn btn-link active"
                  >
                    <i className="las la-list" />
                  </Link> */}
                  {/* <Link to="/contact-grid" className="grid-view btn btn-link">
                    <i className="las la-th" />
                  </Link> */}
                </div>
              </li>
              <li>
                <div className="form-sort value-contain">
                  <i className="las la-sort-alpha-up-alt" />
                  <Select
                    className="form-sort-two w-100"

                    options={sortoption}
                    placeholder="Select By Alphabet"
                    styles={customStyles}
                  />
                </div>
              </li>
              <li>
                <div className={`form-sorts dropdown ${isDropdownOpen ? 'table-filter-show' : ''}`}>
                  <Link onClick={handleToggleDropdown}
                    to="#"
                    className="dropdown-toggle"
                    id="table-filter"
                  >
                    <i className="las la-filter me-2" />
                    Filter
                  </Link>
                  <div className="filter-dropdown-menu">
                    <div className="filter-set-view">
                      <div className="filter-set-head">
                        <h4>Filter</h4>
                      </div>
                      <div className="accordion" id="accordionExample">
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link
                              to="#"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Rating
                              <i className="las la-angle-right" />
                            </Link>
                          </div>
                          <div
                            className="filter-set-contents accordion-collapse collapse show"
                            id="collapseOne"
                            data-bs-parent="#accordionExample"
                          >
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked={true} />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />

                                  <span>5.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <span>4.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />

                                  <span>3.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />


                                  <span>2.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <Star className='me-1' size={18} color='#FFBC34' fill='#FFBC34' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />
                                  <Star className='me-1' size={18} color='#E2E4E6' fill='#E2E4E6' />

                                  <span>1.0</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link
                              to="#"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Owner
                              <i className="las la-angle-right" />
                            </Link>
                          </div>
                          <div
                            className="filter-set-contents accordion-collapse collapse"
                            id="collapseTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked="" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Hendry</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Guillory</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Jami</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Theresa</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Espinosa</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link
                              to="#"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Tags
                              <i className="las la-angle-right" />
                            </Link>
                          </div>
                          <div
                            className="filter-set-contents accordion-collapse collapse"
                            id="collapseThree"
                            data-bs-parent="#accordionExample"
                          >
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked="" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Promotion</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Rated</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Rejected</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Collab</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Calls</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="filter-reset-btns">
                        <Link to="#" className="btn btn-light">
                          Reset
                        </Link>
                        <Link to="#" className="btn btn-primary">
                          Filter
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset">
                      <i className="las la-search" />
                    </Link>
                    <div className="dataTables_filter">
                      <label>
                        {" "}
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder="Search"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table table-striped custom-table datatable contact-table"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={customers}
                  rowKey={(record) => record.id}
                  onRow={(record) => ({
                    onClick: () => navigate(`/customer-details`, { state: { customerData: record } }),
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      <ExportLeads />
      <AddContact />
      <EditContact />
      <DeleteContact />
      <AddNotes />
    </div>

  )
}

export default CustomerListPage
