import React, { useState } from 'react';
import {
    Container, Row, Col, Card,
    Table, Badge, Button,
    ListGroup, Collapse,
} from 'react-bootstrap';
import {
    LineChart, Line, XAxis, YAxis,
    CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, PieChart,
    Pie, Cell, BarChart, Bar
} from 'recharts';
import {
    DollarSign,
    Truck, Clock,
    ArrowRight, MapPin, Phone,
    Mail, User,
    Eye,
} from 'react-feather';

const CustomerAnalyticsDashboard = ({ customerDetails, shipments }) => {

    const getStatusColor = (status) => {
        switch (status) {
            case 'In Transit':
                return 'warning';  // Yellow for "In Transit"
            case 'Delivered':
                return 'success';  // Green for "Delivered"
            case 'Pending':
                return 'secondary';  // Grey for "Pending"
            default:
                return 'primary';  // Default to blue
        }
    };


    const handleRowClick = (id) => {
        setOpenRows((prevOpenRows) =>
            prevOpenRows.includes(id)
                ? prevOpenRows.filter((rowId) => rowId !== id)
                : [...prevOpenRows, id]
        );
    };

    const [openRows, setOpenRows] = useState([]);

    // Shipment Status Analysis
    const shipmentStatusData = [
        { name: 'Delivered', value: shipments.filter(s => s.load_status === 'Delivered').length },
        { name: 'In Transit', value: shipments.filter(s => s.load_status === 'In Transit').length },
        { name: 'Scheduled', value: shipments.filter(s => s.load_status === 'Scheduled').length },
        { name: 'Cancelled', value: shipments.filter(s => s.load_status === 'Cancelled').length }
    ];

    const COLORS = ['#28a745', '#ffc107', '#17a2b8', '#dc3545'];

    // Revenue Analysis
    const revenueData = shipments.map((shipment, index) => ({
        month: `Month ${index + 1}`,
        revenue: shipment.load_tender_details?.rate || 0
    }));

    // Shipment Weight Distribution
    const weightData = [
        { name: 'Light (0-5000 lbs)', value: shipments.filter(s => s.load_weight < 5000).length },
        { name: 'Medium (5000-15000 lbs)', value: shipments.filter(s => s.load_weight >= 5000 && s.load_weight < 15000).length },
        { name: 'Heavy (15000+ lbs)', value: shipments.filter(s => s.load_weight >= 15000).length }
    ];

    // Equipment Type Distribution
    const equipmentTypeData = shipments.reduce((acc, shipment) => {
        acc[shipment.load_equipment_type] = (acc[shipment.load_equipment_type] || 0) + 1;
        return acc;
    }, {});

    const equipmentChartData = Object.entries(equipmentTypeData).map(([name, value]) => ({ name, value }));

    return (
        <Container fluid >
            <Row >

                {/* Revenue and Shipment Charts */}
                <Col className='g-0' lg={8}>
                    <Card className="shadow-sm ">
                        <Card.Header>Revenue by Shipment</Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={revenueData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                {/* Shipment Status Distribution */}
                <Col className='g-0' lg={4}>
                    <Card className="shadow-sm ">
                        <Card.Header>Shipment Status</Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={shipmentStatusData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {shipmentStatusData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className='g-0' lg={12}>
                    <Card className="shadow-sm p-4">
                        <Card.Header className="bg-white py-3 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <Truck className="mr-2" size={20} />
                                <h4 className="mb-0 text-dark font-weight-bold">&nbsp;Recent Shipments</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table hover responsive className="mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="text-muted font-weight-bold align-middle">
                                            <div className="d-flex align-items-center">
                                                <MapPin size={16} className="mr-2" />
                                                &nbsp;Origin
                                            </div>
                                        </th>
                                        <th className="text-muted font-weight-bold align-middle">
                                            <div className="d-flex align-items-center">
                                                <ArrowRight size={16} className="mr-2" />
                                                &nbsp;Destination
                                            </div>
                                        </th>
                                        <th className="text-muted font-weight-bold align-middle">
                                            <div className="d-flex align-items-center">
                                                <Clock size={16} className="mr-2" />&nbsp;
                                                Status
                                            </div>
                                        </th>
                                        <th className="text-muted font-weight-bold align-middle">
                                            <div className="d-flex align-items-center">
                                                <DollarSign size={16} className="mr-2" />
                                                &nbsp;Rate
                                            </div>
                                        </th>
                                        <th className="text-muted font-weight-bold text-center align-middle">
                                        &nbsp;Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipments.slice(0, 5).map((shipment) => (
                                        <React.Fragment key={shipment.id}>
                                            <tr
                                                onClick={() => handleRowClick(shipment.id)}
                                                className="cursor-pointer"
                                            >
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">
                                                            {shipment.load_origin_company_name}
                                                        </span>
                                                        <small className="text-muted">
                                                            {shipment.load_origin_address.city}, {shipment.load_origin_address.state}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">
                                                            {shipment.load_destination_address.city}, {shipment.load_destination_address.state}
                                                        </span>
                                                        <small className="text-muted">
                                                            {shipment.load_equipment_type}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Badge
                                                        pill
                                                        bg={getStatusColor(shipment.load_status)}
                                                        className="font-weight-normal"
                                                    >
                                                        {shipment.load_status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">
                                                            ${shipment.load_tender_details?.rate?.toFixed(2) || 'N/A'}
                                                        </span>
                                                        <small className="text-muted">
                                                            {shipment.load_tender_details?.carrier_name || 'Unassigned'}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td className="text-center align-middle">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        className="d-flex align-items-center justify-content-center mx-auto"
                                                    >
                                                        <Eye size={16} />
                                                    </Button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5" className="p-0">
                                                    <Collapse in={openRows.includes(shipment.id)}>
                                                        <Card className="border-0 m-2">
                                                            <Card.Body className="bg-light rounded">
                                                                <Row className="no-gutters">
                                                                    <Col md={12} className="pr-md-2">
                                                                        <h6 className="text-muted mb-3">Shipment Details</h6>
                                                                        <div className="d-flex flex-column">
                                                                            <div className="mb-2 d-flex ">
                                                                                <strong>Load Tender ID: </strong>
                                                                                <span>&nbsp;&nbsp;{shipment.load_tender_details?.tender_id || 'N/A'}</span>
                                                                            </div>
                                                                            <div className="mb-2 d-flex ">
                                                                                <strong>Origin Contact:</strong>
                                                                                <span>&nbsp;&nbsp;{shipment.load_origin_contact}</span>
                                                                            </div>
                                                                            <div className="mb-2 d-flex ">
                                                                                <strong>Stop Contact:</strong>
                                                                                <span>&nbsp;&nbsp;{shipment.load_stop_contact}</span>
                                                                            </div>
                                                                        </div>
                                                                        <h6 className="text-muted mt-3 mb-3">Acceptance History</h6>
                                                                        <div className="d-flex flex-column">
                                                                            {shipment.load_acceptance_history?.map((history, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="bg-white rounded p-2 mb-2"
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <div className="font-weight-bold">{history.status}</div>
                                                                                        <small className="text-muted">
                                                                                            {new Date(history.timestamp).toLocaleString()}
                                                                                        </small>
                                                                                    </div>
                                                                                    <small className="text-muted">
                                                                                        Updated by {history.updated_by}
                                                                                    </small>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Collapse>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className='g-0' lg={6}>
                    <Card className="shadow-sm">
                        <Card.Header>Equipment Type Distribution</Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={equipmentChartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="value" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className='g-0' lg={6}>
                    <Card className="shadow-sm ">
                        <Card.Header>Shipment Weight Distribution</Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={weightData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {weightData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='g-1' lg={12}>
                    <Card className="shadow-sm">
                        <Card.Header>
                            <h5 className="mb-0">Contact Information</h5>
                        </Card.Header>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <div className="d-flex align-items-center">
                                    <User size={18} className="me-2" />
                                    <div>
                                        <small className="text-muted d-block">Primary Contact</small>
                                        {customerDetails.name}
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="d-flex align-items-center">
                                    <Phone size={18} className="me-2" />
                                    {customerDetails.phone}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="d-flex align-items-center">
                                    <Mail size={18} className="me-2" />
                                    {customerDetails.email}
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CustomerAnalyticsDashboard;