const customerList = {
  customers: [
    {
      id: "C001",
      image_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2d7bF1q2WBQnHUhqaR2OuxjFiv_EIWBwqow&s",
      name: "GraySystems Logistics Inc.",
      contact_info: {
        address: "789 Shipping Way, Chicago, IL 60601",
        phone: "+1-312-555-9876",
        email: "contact@globallogistics.com",
        primary_contact: "Sarah Johnson"
      },
      customer_type: {
        type: "Shipper",
        industry_type: "Manufacturing",
        mc_number: null,
        dot_number: null
      },
      billing_details: {
        billing_address: "789 Shipping Way, Chicago, IL 60601",
        tax_id: "12-3456789",
        payment_terms: "Net 30",
        billing_contact: "Accounts Payable",
        billing_contact_email: "ap@globallogistics.com",
        billing_contact_phone: "+1-312-555-8765"
      },
      contract_details: {
        start_date: "2024-01-01",
        end_date: "2024-12-31",
        terms: "Standard shipping agreement"
      },
      shipping_details: [
        {
          id: "S001",
          image_url: "https://static.vecteezy.com/system/resources/previews/008/296/267/non_2x/colorful-swirl-logo-design-concept-illustration-vector.jpg",

          pickup: {
            company_name: "Global Logistics Inc.",
            address: "123 Manufacturing Plant, Chicago, IL 60602",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-15T09:00:00Z"
            },
            pickup_numbers: ["PU12345"],
            contact: {
              name: "Warehouse Manager",
              phone: "+1-312-555-1111",
              email: "warehouse@globallogistics.com"
            },
            description: "Automotive Parts Shipment"
          },
          delivery: {
            company_name: "Distribution Center West",
            address: "456 Logistics Park, Los Angeles, CA 90001",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-17T14:00:00Z"
            },
            delivery_numbers: ["DEL67890"],
            contact: {
              name: "Receiving Supervisor",
              phone: "+1-310-555-2222",
              email: "receiving@distributioncentral.com"
            },
            description: "Automotive Parts Delivery"
          },
          shipment_details: {
            status: "Scheduled",
            total_weight: 5000,
            weight_unit: "lbs",
            total_volume: 200,
            volume_unit: "cubic_feet"
          },
          pay_details: {
            base_rate: 1250.0,
            additional_fees: 0,
            total_pay: 1250.0
          },
          assigned_driver_id: "D001"
        },
        {
          id: "S001",
          pickup: {
            company_name: "ABC Logistics Inc.",
            address: "123 Manufacturing Plant, Chicago, IL 60602",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-15T09:00:00Z"
            },
            pickup_numbers: ["PU12345"],
            contact: {
              name: "Warehouse Manager",
              phone: "+1-312-555-1111",
              email: "warehouse@globallogistics.com"
            },
            description: "Automotive Parts Shipment"
          },
          delivery: {
            company_name: "Distribution Center West",
            address: "456 Logistics Park, Los Angeles, CA 90001",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-17T14:00:00Z"
            },
            delivery_numbers: ["DEL67890"],
            contact: {
              name: "Receiving Supervisor",
              phone: "+1-310-555-2222",
              email: "receiving@distributioncentral.com"
            },
            description: "Automotive Parts Delivery"
          },
          shipment_details: {
            status: "Scheduled",
            total_weight: 5000,
            weight_unit: "lbs",
            total_volume: 200,
            volume_unit: "cubic_feet"
          },
          pay_details: {
            base_rate: 1250.0,
            additional_fees: 0,
            total_pay: 1250.0
          },
          assigned_driver_id: "D001"
        },
        {
          id: "S001",
          image_url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2d7bF1q2WBQnHUhqaR2OuxjFiv_EIWBwqow&s",

          pickup: {
            company_name: "BCD Logistics Inc.",
            address: "123 Manufacturing Plant, Chicago, IL 60602",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-15T09:00:00Z"
            },
            pickup_numbers: ["PU12345"],
            contact: {
              name: "Warehouse Manager",
              phone: "+1-312-555-1111",
              email: "warehouse@globallogistics.com"
            },
            description: "Automotive Parts Shipment"
          },
          delivery: {
            company_name: "Distribution Center West",
            address: "456 Logistics Park, Los Angeles, CA 90001",
            date_time: {
              type: "Appointment",
              scheduled: "2024-06-17T14:00:00Z"
            },
            delivery_numbers: ["DEL67890"],
            contact: {
              name: "Receiving Supervisor",
              phone: "+1-310-555-2222",
              email: "receiving@distributioncentral.com"
            },
            description: "Automotive Parts Delivery"
          },
          shipment_details: {
            status: "Scheduled",
            total_weight: 5000,
            weight_unit: "lbs",
            total_volume: 200,
            volume_unit: "cubic_feet"
          },
          pay_details: {
            base_rate: 1250.0,
            additional_fees: 0,
            total_pay: 1250.0
          },
          assigned_driver_id: "D001"
        },
        {
          id: "S002",
          image_url: "https://static.vecteezy.com/system/resources/previews/008/296/267/non_2x/colorful-swirl-logo-design-concept-illustration-vector.jpg",

          pickup: {
            company_name: "EFG Logistics Inc.",
            address: "321 Assembly Line Blvd, Detroit, MI 48201",
            date_time: {
              type: "Window",
              scheduled: "2024-07-01T08:00:00Z - 2024-07-01T12:00:00Z"
            },
            pickup_numbers: ["PU54321"],
            contact: {
              name: "Logistics Coordinator",
              phone: "+1-313-555-3333",
              email: "coordinator@globallogistics.com"
            },
            description: "Electronics Shipment"
          },
          delivery: {
            company_name: "Tech Warehouse South",
            address: "789 Silicon Valley Ave, San Jose, CA 94088",
            date_time: {
              type: "Appointment",
              scheduled: "2024-07-03T15:00:00Z"
            },
            delivery_numbers: ["DEL11223"],
            contact: {
              name: "Warehouse Clerk",
              phone: "+1-408-555-4444",
              email: "clerk@techwarehouse.com"
            },
            description: "Electronics Delivery"
          },
          shipment_details: {
            status: "Completed",
            total_weight: 2000,
            weight_unit: "lbs",
            total_volume: 100,
            volume_unit: "cubic_feet"
          },
          pay_details: {
            base_rate: 800.0,
            additional_fees: 50.0,
            total_pay: 850.0
          },
          assigned_driver_id: "D002"
        }
      ],
      shipment_history: {
        total_shipments: 2,
        last_shipment_date: "2024-07-01",
        total_revenue: 2100.0
      }
    }
  ]
};

export default customerList;
