import React from 'react';
import { 
  Row, Col, Card, Table, 
  ProgressBar 
} from 'react-bootstrap';
import { 
  MapPin, Clock, 
  Navigation, Package, DollarSign 
} from 'react-feather';
import { 
  LineChart, Line, XAxis, YAxis, 
  CartesianGrid, Tooltip, ResponsiveContainer 
} from 'recharts';

const ShipmentOverview = ({ shipmentDetails }) => {
  // Mock data for charts
  const milestoneData = [
    { name: 'Pickup', distance: 0, completion: 0 },
    { name: 'En Route', distance: 250, completion: 40 },
    { name: 'Stop', distance: 500, completion: 65 },
    { name: 'Destination', distance: 750, completion: 100 }
  ];

  const rateData = [
    { name: 'Base Rate', amount: shipmentDetails.rate || 0 },
    { name: 'Accessorials', amount: (shipmentDetails.rate * 0.1) || 0 },
    { name: 'Fuel Surcharge', amount: (shipmentDetails.rate * 0.15) || 0 }
  ];

  return (
    <div className="container-fluid p-4">
      <Row className="g-4">
        {/* Route Progress - Full Width on Mobile, 8 Columns on Larger Screens */}
        <Col xs={12} lg={12}>
          <Card className="h-100 border-0 shadow-sm">
            <Card.Body className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <Navigation size={20} className="me-2 text-primary" />
                <h5 className="card-title mb-0">Route Progress</h5>
              </div>
              <div className="flex-grow-1">
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={milestoneData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis dataKey="name" />
                    <YAxis label={{ value: 'Miles', angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Line 
                      type="monotone" 
                      dataKey="distance" 
                      stroke="#8884d8" 
                      activeDot={{ r: 8 }} 
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <ProgressBar 
                now={milestoneData[milestoneData.length - 1].completion} 
                label={`${milestoneData[milestoneData.length - 1].completion}% Complete`} 
                variant="primary"
                className="mt-3"
              />
            </Card.Body>
          </Card>
        </Col>

       

        {/* Shipment Details - Full Width */}
        <Col xs={12}>
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <Row className="g-4">
                {/* Shipment Details Column */}
                <Col xs={12} md={4} className="border-md-end">
                  <div className="d-flex align-items-center mb-3">
                    <Package size={18} className="me-2 text-primary" />
                    <h6 className="mb-0">Shipment Details</h6>
                  </div>
                  <Table borderless>
                    <tbody>
                      {[
                        { label: 'Weight', value: `${shipmentDetails.weight} lbs` },
                        { label: 'Equipment', value: shipmentDetails.equipment },
                        { label: 'Total Distance', value: `${shipmentDetails.distance || 'N/A'} miles` },
                        { 
                          label: 'Status', 
                          value: <span className="badge bg-primary">{shipmentDetails.status}</span> 
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td className="text-muted px-0">{item.label}:</td>
                          <td className="text-end px-0">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>

                {/* Timeline Column */}
                <Col xs={12} md={4} className="border-md-end">
                  <div className="d-flex align-items-center mb-3">
                    <Clock size={18} className="me-2 text-warning" />
                    <h6 className="mb-0">Timeline</h6>
                  </div>
                  <Table borderless>
                    <tbody>
                      {[
                        { 
                          label: 'Origin Window', 
                          value: `${new Date(shipmentDetails.origin?.windowStart).toLocaleString()} - 
                                  ${new Date(shipmentDetails.origin?.windowEnd).toLocaleString()}`
                        },
                        { 
                          label: 'Stop Window', 
                          value: `${new Date(shipmentDetails.stop?.windowStart).toLocaleString()} - 
                                  ${new Date(shipmentDetails.stop?.windowEnd).toLocaleString()}`
                        }
                      ].map((item, index) => (
                        <tr key={index}>
                          <td className="text-muted px-0">{item.label}:</td>
                          <td className="text-end px-0">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>

                {/* Route Map Column */}
                {/* <Col xs={12} md={4}>
                  <div className="d-flex align-items-center mb-3">
                    <MapPin size={18} className="me-2 text-success" />
                    <h6 className="mb-0">Route Map</h6>
                  </div>
                  <div 
                    className="d-flex justify-content-center align-items-center bg-light rounded-3" 
                    style={{ 
                      width: '100%', 
                      height: '200px',
                      border: '2px dashed #e0e0e0'
                    }}
                  >
                    <p className="text-muted mb-0">Map Placeholder</p>
                  </div>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </Col>
         {/* Rate Breakdown - Full Width on Mobile, 4 Columns on Larger Screens */}
         <Col xs={12} lg={12}>
          <Card className="h-100 border-0 shadow-sm">
            <Card.Body className="d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <DollarSign size={20} className="me-2 text-success" />
                <h5 className="card-title mb-0">Rate Breakdown</h5>
              </div>
              <div className="flex-grow-1">
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart data={rateData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis dataKey="name" />
                    <YAxis label={{ value: 'USD', angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Line 
                      type="monotone" 
                      dataKey="amount" 
                      stroke="#82ca9d" 
                      activeDot={{ r: 8 }} 
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <Table size="sm" className="mt-3 mb-0">
                <tbody>
                  {rateData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-muted">{item.name}</td>
                      <td className="text-end text-dark">${item.amount.toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr className="table-active">
                    <td><strong className="text-dark">Total</strong></td>
                    <td className="text-end">
                      <strong className="text-success">
                        ${rateData.reduce((sum, item) => sum + item.amount, 0).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShipmentOverview;