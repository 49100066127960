import React, { useEffect, useRef, useState } from 'react'
import { avatar1, avatar19, avatar20, avatar21, avatar22, company_icon_01, company_icon_02, media35 } from '../../../Routes/ImagePath'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import CompaniesDetailModal from '../../../components/modelpopup/CompaniesDetailModal';
import CompanyEditModal from '../../../components/modelpopup/Crm/CompanyEditModal';
import { Calendar, Edit, Mail, MessageCircle, MoreVertical, Phone, PlusCircle, Star, Trash2 } from 'react-feather';
import { useLocation } from 'react-router-dom';
import ShipmentOverview from './shipmentOverview';
import { ShipmentDocuments, ShipmentTracking } from './shipmentTrackingandDoc'
// import {Mail, MessageCircle, Edit, MoreVertical } from 'lucide-react';


const CompanyDetails = () => {
  const location = useLocation();
  const shipmentDetails = location.state?.shipmentDetails || {};
  console.log(shipmentDetails)

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };


  const optionsSortValue = [
    { value: 'sortDate', label: 'Sort By Date' },
    { value: 'ascending', label: 'Ascending' },
    { value: 'descending', label: 'Descending' }

  ];

  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener and exit fullscreen on component unmount
    return () => {
      maximizeBtn.removeEventListener('click', handleClick);
      cleanup();
    };
  }, [isFullScreen]);


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (


    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="page-title">Shipments</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/admin-dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item active">Shipments</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <div className="view-icons">
                  <Link to="#" className="grid-view btn btn-link"><i className="las la-redo-alt" /></Link>
                  <Link to="#" className="list-view btn btn-link" id="collapse-header" ref={maximizeBtnRef}><i className="las la-expand-arrows-alt" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <hr />
        <div className="row">
          {/* Contact User */}
          <div className="col-md-12">
            {/* Header Section */}
            <div className="contact-head bg-light p-3 border-bottom">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <ul className="contact-breadcrumb list-unstyled d-flex align-items-center m-0 text-muted">
                    <li className="me-2">
                      <Link to="/companies" className="text-decoration-none text-secondary d-flex align-items-center">
                        <i className="las la-arrow-left me-1" />
                        <span className="">Shipments</span>
                      </Link>
                    </li>
                    <li className=" ">{shipmentDetails.origin.companyName}</li>
                  </ul>
                </div>
                <div className="col-sm-6 text-sm-end">
                  <div className="contact-pagination d-flex align-items-center justify-content-end">
                    <p className="text-muted mb-0 me-3">1 of 40</p>
                    <ul className="list-unstyled d-flex align-items-center m-0">
                      <li className="me-2">
                        <Link to="/company-details" className="text-secondary">
                          <i className="las la-arrow-left fs-5" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/company-details" className="text-secondary">
                          <i className="las la-arrow-right fs-5" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Wrap Section */}
            <div className="contact-wrap p-4">
              {/* Origin Company Profile */}
              <div className="contact-profile mb-4 d-flex align-items-center">
                <div className="avatar company-avatar">
                  <span className="text-icon">{shipmentDetails.origin.companyName.substring(0, 2).toUpperCase()}</span>
                </div>
                <div className="name-user">
                  <h4 className="mb-2 fw-bold">{shipmentDetails.origin.companyName}</h4>
                  <p className="text-muted mb-1">
                    <i className="las la-map-marker me-2" />
                    {shipmentDetails.origin.address}
                  </p>
                  <div className="badge-rate">
                    <p className="text-warning mb-0">
                      <i className="fa-solid fa-star me-1" />
                      {shipmentDetails.origin.contact}
                    </p>
                  </div>
                </div>
              </div>

              {/* Destination Company Profile */}
              <div className="contact-profile mb-4 d-flex justify-content-end text-end">
                <div className="name-user me-3">
                  <h4 className="mb-2 fw-bold">{shipmentDetails.destination.companyName}</h4>
                  <p className="text-muted mb-1">
                    <i className="las la-map-marker me-2" />
                    {shipmentDetails.destination.address}
                  </p>
                </div>
                <div className=" avatar company-avatar">
                  <span className="text-icon">{shipmentDetails.destination.companyName.substring(0, 2).toUpperCase()}</span>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="contacts-action d-flex justify-content-center gap-3 mt-4 pt-3 border-top">
                <Link to="#" className="btn btn-outline-warning rounded-circle p-2">
                  <i className="fa-solid fa-star" />
                </Link>
                <Link
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#add_deals"
                  className="btn btn-pink d-flex align-items-center"
                >
                  <PlusCircle className='me-2' size={18} />
                  Assign Carrier
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#add_compose"
                >
                  <Mail className='me-2' size={18} />
                  Send Email
                </Link>
                <Link to="/chat" className="btn btn-outline-secondary rounded-circle p-2">
                  <MessageCircle size={18} />
                </Link>
                <Link
                  to="#"
                  className="btn btn-outline-secondary rounded-circle p-2"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_company"
                >
                  <Edit size={18} />
                </Link>
                <div className="dropdown">
                  <Link
                    to="#"
                    className="btn btn-outline-secondary rounded-circle p-2 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <MoreVertical size={18} />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link
                      className="dropdown-item text-danger"
                      // to="#"
                      // data-bs-toggle="modal"
                      // data-bs-target="#delete_company"
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Contact User */}
          {/* Contact Sidebar */}
          <div className="col-xl-3">
            <div className="card contact-sidebar">
              {/* Basic Information */}
              <h5 className="mb-3">Basic Information</h5>
              <ul className="basic-info list-unstyled">
                <li className="d-flex align-items-center mb-2">
                  <span className="me-2"><Mail size={15} /></span>
                  <p className="mb-0">{shipmentDetails.origin.contact.split(',')[1].trim()}</p>
                </li>
                <li className="d-flex align-items-center mb-2">
                  <span className="me-2"><Phone size={15} /></span>
                  <p className="mb-0">{shipmentDetails.origin.contact.split(',')[2].trim()}</p>
                </li>
                <li className="d-flex align-items-center mb-2">
                  <span className="me-2"><Calendar size={15} /></span>
                  <p className="mb-0">Created on {formatDate(shipmentDetails.origin.windowStart)}</p>
                </li>
              </ul>

              {/* Other Information */}
              <h5 className="mt-3 mb-3">Other Information</h5>
              <ul className="other-info list-unstyled">
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Tender ID</span>
                  <span>{shipmentDetails.tender_id}</span>
                </li>
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Status</span>
                  <span>{shipmentDetails.status}</span>
                </li>
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Carrier</span>
                  <span>{shipmentDetails.carrier}</span>
                </li>
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Equipment</span>
                  <span>{shipmentDetails.equipment}</span>
                </li>
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Weight</span>
                  <span>{shipmentDetails.weight} lbs</span>
                </li>
                <li className="d-flex justify-content-between mb-2">
                  <span className="other-title">Rate</span>
                  <span>${shipmentDetails.rate.toFixed(2)}</span>
                </li>
              </ul>

              {/* Tags */}
              <h5 className="mt-3 mb-3">Tags</h5>
              <ul className="tag-info list-unstyled d-flex gap-2">
                <li>
                  <Link to="#" className="badge bg-success-light text-decoration-none">In Transit</Link>
                </li>
                <li>
                  <Link to="#" className="badge bg-warning-light text-decoration-none">Active</Link>
                </li>
              </ul>

              {/* Companies */}
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-3 mb-3">
                <h5 className="mb-0">Shipment Route</h5>
              </div>
              <ul className="company-info com-info list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  {/* <span className="me-3">
              <img 
                src={shipmentDetails.logo} 
                alt="Origin Company" 
                className="rounded-circle" 
                style={{width: '50px', height: '50px', objectFit: 'cover'}} 
              />
            </span> */}
                  <div>
                    <h6 className="mb-1">{shipmentDetails.origin.companyName}</h6>
                    <p className="text-muted mb-0">
                      <i className="las la-map-marker me-1" />
                      {shipmentDetails.origin.address}
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  {/* <span className="me-3">
              <img 
                src={shipmentDetails.logo} 
                alt="Stop Company" 
                className="rounded-circle" 
                style={{width: '50px', height: '50px', objectFit: 'cover'}} 
              />
            </span> */}
                  <div>
                    <h6 className="mb-1">{shipmentDetails.stop.companyName}</h6>
                    <p className="text-muted mb-0">
                      <i className="las la-map-marker me-1" />
                      {shipmentDetails.stop.address}
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  {/* <span className="me-3">
              <img 
                src={shipmentDetails.logo} 
                alt="Destination Company" 
                className="rounded-circle" 
                style={{width: '50px', height: '50px', objectFit: 'cover'}} 
              />
            </span> */}
                  <div>
                    <h6 className="mb-1">{shipmentDetails.destination.companyName}</h6>
                    <p className="text-muted mb-0">
                      <i className="las la-map-marker me-1" />
                      {shipmentDetails.destination.address}
                    </p>
                  </div>
                </li>
              </ul>

              {/* Social Profile */}
              <h5 className="mt-3 mb-3">Social Profile</h5>
              <ul className="social-info list-unstyled d-flex gap-3">
                {['facebook-f', 'whatsapp', 'linkedin'].map((social) => (
                  <li key={social}>
                    <Link to="#" className="text-muted">
                      <i className={`fa-brands fa-${social}`} />
                    </Link>
                  </li>
                ))}
              </ul>

              {/* Settings */}
              <h5 className="mt-3 mb-3">Settings</h5>
              <ul className="set-info list-unstyled">
                <li className="mb-2">
                  <Link to="#" className="text-muted text-decoration-none">
                    <i className="las la-upload me-2" />Share Shipment
                  </Link>
                </li>
                {/* <li className="mb-2">
                  <Link to="#" className="text-muted text-decoration-none">
                    <Star className="me-2" size={15} />Add to Favourite
                  </Link>
                </li> */}
                <li>
                  <Link
                    // to="#"
                    // className="text-danger text-decoration-none"
                    // data-bs-toggle="modal"
                    // data-bs-target="#delete_shipment"
                  >
                    <Trash2 className="me-2" size={15} />Delete Shipment
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* /Contact Sidebar */}
          {/* Contact Details */}
          <div className="col-xl-9">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#activities" className="active"><i className="las la-user-clock" />Overview</Link>
                </li>
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#notes"><i className="las la-file" />Notes</Link>
                </li>
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#calls"><i className="las la-phone-volume" />Track</Link>
                </li>
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#files"><i className="las la-file" />Files</Link>
                </li>
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#email"><i className="las la-envelope" />Email</Link>
                </li>
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* Activities */}
                <div className="tab-pane active show" id="activities">
                  <div className="view-header">
                    <h4>Overview</h4>
                    <ul>
                      <li>
                        <div className="form-sort sort-date">
                          <i className="las la-sort-amount-up-alt" />
                          <Select
                            options={optionsSortValue}
                            placeholder="Sort By Date"
                            className="select w-100"
                            isSearchable={false}
                            styles={customStyles}

                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ShipmentOverview shipmentDetails={shipmentDetails} />
                  </div>
                  <div className="contact-activity">
                    <div className="badge-day"><i className="fa-regular fa-calendar-check" />15 Feb 2024</div>
                    <ul>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-info">
                          <i className="las la-comment-alt" />
                        </span>
                        <div className="activity-info">
                          <h6>You sent 1 Message to the contact.</h6>
                          <p>10:25 pm</p>
                        </div>
                      </li>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-success">
                          <i className="las la-phone" />
                        </span>
                        <div className="activity-info">
                          <h6>Denwar responded to your appointment schedule question by call at 09:30pm.</h6>
                          <p>09:25 pm</p>
                        </div>
                      </li>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-warning">
                          <i className="las la-file-alt" />
                        </span>
                        <div className="activity-info">
                          <h6>Notes added by Antony</h6>
                          <p>Please accept my apologies for the inconvenience caused. It would be much appreciated if it's possible to reschedule to 6:00 PM, or any other day that week.</p>
                          <p>10.00 pm</p>
                        </div>
                      </li>
                    </ul>
                    <div className="badge-day"><i className="fa-regular fa-calendar-check" />15 Feb 2024</div>
                    <ul>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-pending">
                          <i className="las la-user-tie" />
                        </span>
                        <div className="activity-info">
                          <h6>Meeting With <span className="avatar-xs"><img src={avatar19} alt="img" /></span> Abraham</h6>
                          <p>Schedueled  on 05:00 pm</p>
                        </div>
                      </li>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-success">
                          <i className="las la-phone" />
                        </span>
                        <div className="activity-info">
                          <h6>Drain responded to your appointment schedule question.</h6>
                          <p>09:25 pm</p>
                        </div>
                      </li>
                    </ul>
                    <div className="badge-day"><i className="fa-regular fa-calendar-check" />Upcoming Activity</div>
                    <ul>
                      <li className="activity-wrap">
                        <span className="activity-icon bg-pending">
                          <i className="las la-user-tie" />
                        </span>
                        <div className="activity-info">
                          <h6>Product Meeting</h6>
                          <p>A product team meeting is a gathering of the cross-functional product team — ideally including team members from product, engineering, marketing, and customer support.</p>
                          <p>25 Jul 2023, 05:00 pm</p>
                          <div className="upcoming-info">
                            <div className="row">
                              <div className="col-sm-4">
                                <p>Reminder</p>
                                <div className="dropdown">
                                  <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="las la-clock me-1" />Reminder<i className="las la-angle-down ms-1" /></Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link className="dropdown-item" to="#">Remainder</Link>
                                    <Link className="dropdown-item" to="#">1 hr</Link>
                                    <Link className="dropdown-item" to="#">10 hr</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <p>Task Priority</p>
                                <div className="dropdown">
                                  <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-circle me-1 text-danger circle" />High<i className="las la-angle-down ms-1" /></Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link className="dropdown-item" to="#"><i className="fa-solid fa-circle me-1 text-danger circle" />High</Link>
                                    <Link className="dropdown-item" to="#"><i className="fa-solid fa-circle me-1 text-success circle" />Low</Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <p>Assigned to</p>
                                <div className="dropdown">
                                  <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={avatar19} alt="img" className="me-1 avatar-xs" />John<i className="las la-angle-down ms-1" /></Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link className="dropdown-item" to="#"><img src={avatar19} alt="img" className="me-1 avatar-xs" />John</Link>
                                    <Link className="dropdown-item" to="#"><img src={avatar19} alt="img" className="me-1 avatar-xs" />Peter</Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* /Activities */}
                {/* Notes */}
                <div className="tab-pane fade" id="notes">
                  <div className="view-header">
                    <h4>Notes</h4>
                    <ul>
                      <li>
                        <div className="form-sort sort-date">
                          <i className="las la-sort-amount-up-alt" />
                          <Select
                            options={optionsSortValue}
                            placeholder="Sort By Date"
                            className="select w-100"
                            isSearchable={false}
                            styles={customStyles}

                          />
                        </div>
                      </li>
                      <li>
                        <Link to="#" data-bs-toggle="modal" data-bs-target="#add_notes" className="com-add"><i className="las la-plus-circle me-1" />Add New</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="notes-activity">
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar19} alt="img" />
                          <div>
                            <h6>Darlee Robertson</h6>
                            <p>15 Sep 2023, 12:10 pm</p>
                          </div>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown action-drop">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                              <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Notes added by Antony</h5>
                      <p>A project review evaluates the success of an initiative and identifies areas for improvement. It can also evaluate a current project to determine whether it's on the right track. Or, it can determine the success of a completed project. </p>
                      <ul>
                        <li>
                          <div className="note-download">
                            <div className="note-info">
                              <span className="note-icon bg-success">
                                <i className="las la-file-excel" />
                              </span>
                              <div>
                                <h6>Project Specs.xls</h6>
                                <p>365 KB</p>
                              </div>
                            </div>
                            <Link to="#"><i className="las la-download" /></Link>
                          </div>
                        </li>
                        <li>
                          <div className="note-download">
                            <div className="note-info">
                              <span className="note-icon">
                                <img src={media35} alt="img" />
                              </span>
                              <div>
                                <h6>090224.jpg</h6>
                                <p>365 KB</p>
                              </div>
                            </div>
                            <Link to="#"><i className="las la-download" /></Link>
                          </div>
                        </li>
                      </ul>
                      <div className="notes-editor">
                        <div className="note-edit-wrap">
                          <div className="summernote">Write a new comment, send your team notification by typing @ followed by their name</div>
                          <div className="text-end note-btns">
                            <Link to="#" className="btn btn-lighter add-cancel">Cancel</Link>
                            <Link to="#" className="btn btn-primary">Save</Link>
                          </div>
                        </div>
                        <div className="text-end">
                          <Link to="#" className="add-comment"><i className="las la-plus-circle me-1" />Add Comment</Link>
                        </div>
                      </div>
                    </div>
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar20} alt="img" />
                          <div>
                            <h6>Sharon Roy</h6>
                            <p>18 Sep 2023, 09:52 am</p>
                          </div>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown action-drop">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                              <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5>Notes added by Antony</h5>
                      <p>A project plan typically contains a list of the essential elements of a project, such as stakeholders, scope, timelines, estimated cost and communication methods. The project manager typically lists the information based on the assignment.</p>
                      <ul>
                        <li>
                          <div className="note-download">
                            <div className="note-info">
                              <span className="note-icon bg-success">
                                <i className="las la-file-excel" />
                              </span>
                              <div>
                                <h6>Andrewpass.txt</h6>
                                <p>365 KB</p>
                              </div>
                            </div>
                            <Link to="#"><i className="las la-download" /></Link>
                          </div>
                        </li>
                      </ul>
                      <div className="reply-box">
                        <p>The best way to get a project done faster is to start sooner. A goal without a timeline is just a dream.The goal you set must be challenging. At the same time, it should be realistic and attainable, not impossible to reach.</p>
                        <p>Commented by <span className="text-primary">Aeron</span> on 15 Sep 2023, 11:15 pm</p>
                      </div>
                      <div className="notes-editor">
                        <div className="note-edit-wrap">
                          <div className="summernote">Write a new comment, send your team notification by typing @ followed by their name</div>
                          <div className="text-end note-btns">
                            <Link to="#" className="btn btn-lighter add-cancel">Cancel</Link>
                            <Link to="#" className="btn btn-primary">Save</Link>
                          </div>
                        </div>
                        <div className="text-end">
                          <Link to="#" className="add-comment"><i className="las la-plus-circle me-1" />Add Comment</Link>
                        </div>
                      </div>
                    </div>
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar21} alt="img" />
                          <div>
                            <h6>Vaughan</h6>
                            <p>20 Sep 2023, 10:26 pm</p>
                          </div>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown action-drop">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                              <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>Projects play a crucial role in the success of organizations, and their importance cannot be overstated. Whether it's launching a new product, improving an existing</p>
                      <div className="notes-editor">
                        <div className="note-edit-wrap">
                          <div className="summernote">Write a new comment, send your team notification by typing @ followed by their name</div>
                          <div className="text-end note-btns">
                            <Link to="#" className="btn btn-lighter add-cancel">Cancel</Link>
                            <Link to="#" className="btn btn-primary">Save</Link>
                          </div>
                        </div>
                        <div className="text-end">
                          <Link to="#" className="add-comment"><i className="las la-plus-circle me-1" />Add Comment</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Notes */}
                {/* Calls */}
                <div className="tab-pane fade" id="calls">
                  <div className="view-header">
                    <h4>Tracking</h4>
                    <ul>
                      <li>
                        <Link to="#" data-bs-toggle="modal" data-bs-target="#create_call" className="com-add"><i className="las la-plus-circle me-1" />Add New</Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ShipmentTracking shipmentDetails={shipmentDetails} />
                  </div>
                  <div className="calls-activity">
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar19} alt="img" />
                          <p><span>Darlee Robertson</span> logged a call on 23 Jul 2023, 10:00 pm</p>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown call-drop">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Busy<i className="las la-angle-down ms-1" /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Busy</Link>
                              <Link className="dropdown-item" to="#">No Answer</Link>
                              <Link className="dropdown-item" to="#">Unavailable</Link>
                              <Link className="dropdown-item" to="#">Wrong Number</Link>
                              <Link className="dropdown-item" to="#">Left Voice Message</Link>
                              <Link className="dropdown-item" to="#">Moving Forward</Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>A project review evaluates the success of an initiative and identifies areas for improvement. It can also evaluate a current project to determine whether it's on the right track. Or, it can determine the success of a completed project. </p>
                    </div>
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar20} alt="img" />
                          <p><span>Sharon Roy</span> logged a call on 28 Jul 2023, 09:00 pm</p>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown call-drop">
                            <Link to="#" className="dropdown-toggle bg-light-pending" data-bs-toggle="dropdown" aria-expanded="false">No Answer<i className="las la-angle-down ms-1" /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Busy</Link>
                              <Link className="dropdown-item" to="#">No Answer</Link>
                              <Link className="dropdown-item" to="#">Unavailable</Link>
                              <Link className="dropdown-item" to="#">Wrong Number</Link>
                              <Link className="dropdown-item" to="#">Left Voice Message</Link>
                              <Link className="dropdown-item" to="#">Moving Forward</Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>A project plan typically contains a list of the essential elements of a project, such as stakeholders, scope, timelines, estimated cost and communication methods. The project manager typically lists the information based on the assignment.</p>
                    </div>
                    <div className="calls-box">
                      <div className="caller-info">
                        <div className="calls-user">
                          <img src={avatar21} alt="img" />
                          <p><span>Vaughan</span> logged a call on 30 Jul 2023, 08:00 pm</p>
                        </div>
                        <div className="calls-action">
                          <div className="dropdown call-drop">
                            <Link to="#" className="dropdown-toggle bg-light-pending" data-bs-toggle="dropdown" aria-expanded="false">No Answer<i className="las la-angle-down ms-1" /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Busy</Link>
                              <Link className="dropdown-item" to="#">No Answer</Link>
                              <Link className="dropdown-item" to="#">Unavailable</Link>
                              <Link className="dropdown-item" to="#">Wrong Number</Link>
                              <Link className="dropdown-item" to="#">Left Voice Message</Link>
                              <Link className="dropdown-item" to="#">Moving Forward</Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link className="dropdown-item" to="#">Delete</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>Projects play a crucial role in the success of organizations, and their importance cannot be overstated. Whether it's launching a new product, improving an existing</p>
                    </div>
                  </div>
                </div>
                {/* /Calls */}
                {/* Files */}
                <div className="tab-pane fade" id="files">
                  <div className="view-header">
                    <h4>Files</h4>
                  </div>
                  <div className="files-activity">
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            <h4>Manage Documents</h4>
                            <p>Send customizable quotes, proposals and contracts to close deals faster.</p>
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          <ul className="file-action">
                            <li>
                              <Link to="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#new_file">Create Document</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ShipmentDocuments shipmentDetails={shipmentDetails} />
                    </div>
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            <h4>Collier-Turner Proposal</h4>
                            <p>Send customizable quotes, proposals and contracts to close deals faster.</p>
                            <div className="file-user">
                              <img src={avatar21} alt="img" />
                              <div>
                                <p><span>Owner</span> Vaughan</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          <ul className="file-action">
                            <li>
                              <span className="badge badge-soft-pink">Proposal</span>
                            </li>
                            <li>
                              <span className="badge badge-soft-grey priority-badge"><i className="fa-solid fa-circle" />Low</span>
                            </li>
                            <li>
                              <div className="dropdown action-drop">
                                <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-download me-1" />Download</Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            <h4>Collier-Turner Proposal</h4>
                            <p>Send customizable quotes, proposals and contracts to close deals faster.</p>
                            <div className="file-user">
                              <img src={avatar1} alt="img" />
                              <div>
                                <p><span>Owner</span> Jessica</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          <ul className="file-action">
                            <li>
                              <span className="badge badge-soft-info">Quote</span>
                            </li>
                            <li>
                              <span className="badge badge-soft-success priority-badge"><i className="fa-solid fa-circle" />Sent</span>
                            </li>
                            <li>
                              <div className="dropdown action-drop">
                                <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-download me-1" />Download</Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            <h4>Collier-Turner Proposal</h4>
                            <p>Send customizable quotes, proposals and contracts to close deals faster.</p>
                            <div className="file-user">
                              <img src={avatar22} alt="img" />
                              <div>
                                <p><span>Owner</span> Vaughan</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          <ul className="file-action">
                            <li>
                              <span className="badge badge-soft-pink">Proposal</span>
                            </li>
                            <li>
                              <span className="badge badge-soft-grey priority-badge"><i className="fa-solid fa-circle" />Low</span>
                            </li>
                            <li>
                              <div className="dropdown action-drop">
                                <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> <MoreVertical size={15} /></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#"><i className="las la-edit me-1" />Edit</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-trash me-1" />Delete</Link>
                                  <Link className="dropdown-item" to="#"><i className="las la-download me-1" />Download</Link>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Files */}
                {/* Email */}
                <div className="tab-pane fade" id="email">
                  <div className="view-header">
                    <h4>Email</h4>
                    <ul>
                      <li>
                        <Link to="#" className="com-add create-mail" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-custom-class="tooltip-dark" data-bs-original-title="There are no email accounts configured, Please configured your email account in order to Send/ Create EMails"><i className="las la-plus-circle me-1" />Create Email</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="files-activity">
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            <h4>Manage Emails</h4>
                            <p>You can send and reply to emails directly via this section.</p>
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          <ul className="file-action">
                            <li>
                              <Link to="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#create_email">Connect Account</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="files-wrap">
                      <div className="email-header">
                        <div className="row">
                          <div className="col top-action-left">
                            <div className="float-start d-none d-sm-block">
                              <input type="text" placeholder="Search Messages" className="form-control search-message" />
                            </div>
                          </div>
                          <div className="col-auto top-action-right">
                            <div className="text-end">
                              <button type="button" title="Refresh" data-bs-toggle="tooltip" className="btn btn-white d-none d-md-inline-block"><i className="fa-solid fa-rotate" /></button>
                              <div className="btn-group">
                                <Link className="btn btn-white"><i className="fa-solid fa-angle-left" /></Link>
                                <Link className="btn btn-white"><i className="fa-solid fa-angle-right" /></Link>
                              </div>
                            </div>
                            <div className="text-end">
                              <span className="text-muted d-none d-md-inline-block">Showing 10 of 112 </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="email-content">
                        <div className="table-responsive">
                          <table className="table table-inbox table-hover">
                            <thead>
                              <tr>
                                <th colSpan={6} className="ps-2">
                                  <input type="checkbox" className="checkbox-all" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="unread clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa fa-star starred " /></span></td>
                                <td className="name">John Doe</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td><i className="fa-solid fa-paperclip" /></td>
                                <td className="mail-date">13:14</td>
                              </tr>
                              <tr className="unread clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">Envato Account</td>
                                <td className="subject">Important account security update from Envato</td>
                                <td />
                                <td className="mail-date">8:42</td>
                              </tr>
                              <tr className="clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">Twitter</td>
                                <td className="subject">HRMS Bootstrap Admin Template</td>
                                <td />
                                <td className="mail-date">30 Nov</td>
                              </tr>
                              <tr className="unread clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">Richard Parker</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td />
                                <td className="mail-date">18 Sep</td>
                              </tr>
                              <tr className="clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">John Smith</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td />
                                <td className="mail-date">21 Aug</td>
                              </tr>
                              <tr className="clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">me, Robert Smith (3)</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td />
                                <td className="mail-date">1 Aug</td>
                              </tr>
                              <tr className="unread clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">Codecanyon</td>
                                <td className="subject">Welcome To Codecanyon</td>
                                <td />
                                <td className="mail-date">Jul 13</td>
                              </tr>
                              <tr className="clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">Richard Miles</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td><i className="fa-solid fa-paperclip" /></td>
                                <td className="mail-date">May 14</td>
                              </tr>
                              <tr className="unread clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa-regular fa-star" /></span></td>
                                <td className="name">John Smith</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td />
                                <td className="mail-date">11/11/16</td>
                              </tr>
                              <tr className="clickable-row" data-to="/mail-view">
                                <td>
                                  <input type="checkbox" className="checkmail" />
                                </td>
                                <td><span className="mail-important"><i className="fa fa-star starred " /></span></td>
                                <td className="name">Mike Litorus</td>
                                <td className="subject">Lorem ipsum dolor sit amet, consectetuer adipiscing elit</td>
                                <td />
                                <td className="mail-date">10/31/16</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Email */}
              </div>
            </div>
            {/* /Tab Content */}
          </div>
          {/* /Contact Details */}
        </div>
      </div>

      <CompaniesDetailModal />
      <CompanyEditModal />
    </div>

  )
}

export default CompanyDetails