import React, { useState } from 'react';
import { Card, Table, Badge, Modal, Button, Row, Col } from 'react-bootstrap';
import { 
  Truck, Shield, Tool, Calendar, Check, 
  AlertTriangle, MapPin 
} from 'react-feather';

const AssignedEquipmentTab = ({ driverDetails }) => {
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  // Simulated equipment data
  const equipmentData = [
    {
      id: 'A342',
      type: 'Truck',
      make: 'Freightliner',
      model: 'Cascadia',
      year: 2022,
      vin: '1FUJHHDRXNLK12345',
      licensePlate: 'TX-ABC-1234',
      lastMaintenance: '2024-01-15',
      status: 'Active',
      condition: 'Excellent',
      assignedDate: '2023-11-01',
      nextScheduledMaintenance: '2024-03-15'
    },
    {
      id: 'T124',
      type: 'Trailer',
      make: 'Great Dane',
      model: 'Everest',
      year: 2021,
      vin: '5HJUHHD45NLKX6789',
      licensePlate: 'TX-DEF-5678',
      lastMaintenance: '2024-02-01',
      status: 'Active',
      condition: 'Good',
      assignedDate: '2023-12-15',
      nextScheduledMaintenance: '2024-04-01'
    }
  ];

  const handleShowDetails = (equipment) => {
    setSelectedEquipment(equipment);
    setShowDetailsModal(true);
  };

  const getStatusBadge = (status) => {
    switch(status) {
      case 'Active': return 'success';
      case 'Maintenance': return 'warning';
      case 'Inactive': return 'secondary';
      default: return 'info';
    }
  };

  const getConditionBadge = (condition) => {
    switch(condition) {
      case 'Excellent': return 'success';
      case 'Good': return 'primary';
      case 'Fair': return 'warning';
      case 'Poor': return 'danger';
      default: return 'secondary';
    }
  };

  return (
    <>
      <Card className="shadow-sm">
        <Card.Header className="text-white d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Assigned Equipment</h5>
          <small className='text-muted'>{driverDetails.fullName} - Equipment Inventory</small>
        </Card.Header>
        <Card.Body className="p-0">
          <Table hover responsive className="mb-0">
            <thead>
              <tr>
                <th>Equipment ID</th>
                <th>Type</th>
                <th>Make/Model</th>
                <th>Status</th>
                <th>Condition</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {equipmentData.map((equipment) => (
                <tr key={equipment.id}>
                  <td>{equipment.id}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      {equipment.type === 'Truck' ? <Truck size={16} /> : <Shield size={16} />}
                      <span className="ms-2">{equipment.type}</span>
                    </div>
                  </td>
                  <td>{equipment.make} {equipment.model} ({equipment.year})</td>
                  <td>
                    <Badge 
                      bg={getStatusBadge(equipment.status)} 
                      className="text-white"
                    >
                      {equipment.status}
                    </Badge>
                  </td>
                  <td>
                    <Badge 
                      bg={getConditionBadge(equipment.condition)} 
                      className="text-white"
                    >
                      {equipment.condition}
                    </Badge>
                  </td>
                  <td>
                    <Button 
                      variant="outline-primary" 
                      size="sm"
                      onClick={() => handleShowDetails(equipment)}
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Equipment Details Modal */}
      {selectedEquipment && (
        <Modal 
          show={showDetailsModal} 
          onHide={() => setShowDetailsModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Equipment Details: {selectedEquipment.id}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Card className="mb-3">
                  <Card.Header className="bg-light">
                    <h6 className="mb-0">Basic Information</h6>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <Truck size={16} className="me-2" />
                        Type
                      </Col>
                      <Col>{selectedEquipment.type}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <Tool size={16} className="me-2" />
                        Make/Model
                      </Col>
                      <Col>{selectedEquipment.make} {selectedEquipment.model}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <Calendar size={16} className="me-2" />
                        Year
                      </Col>
                      <Col>{selectedEquipment.year}</Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-3">
                  <Card.Header className="bg-light">
                    <h6 className="mb-0">Identification</h6>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <Shield size={16} className="me-2" />
                        VIN
                      </Col>
                      <Col>{selectedEquipment.vin}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <MapPin size={16} className="me-2" />
                        License Plate
                      </Col>
                      <Col>{selectedEquipment.licensePlate}</Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header className="bg-light">
                    <h6 className="mb-0">Maintenance</h6>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <Check size={16} className="me-2" />
                        Last Maintenance
                      </Col>
                      <Col>{new Date(selectedEquipment.lastMaintenance).toLocaleDateString()}</Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={4} className="text-muted">
                        <AlertTriangle size={16} className="me-2" />
                        Next Maintenance
                      </Col>
                      <Col>{new Date(selectedEquipment.nextScheduledMaintenance).toLocaleDateString()}</Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AssignedEquipmentTab;
