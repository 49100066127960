import React, { useState } from 'react';
import {
    Card,
    Table,
    Badge,
    Button,
    Dropdown,
    Collapse,
    Modal,
    Form,
    Row,
    Col
} from 'react-bootstrap';
import {
    Eye,
    Edit,
    Trash2,
    MoreVertical,
    MapPin,
    Calendar,
    Truck,
    DollarSign,
    Package,
    User,
} from 'lucide-react';
import { ENDPOINT } from '../../../Config/Constants';
import axios from 'axios';

const ShipmentsTab = ({
    shipments,
    onUpdateShipments,
}) => {
    const [openRows, setOpenRows] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false)

    // Handle row expansion
    const handleRowClick = (id) => {
        setOpenRows((prevOpenRows) =>
            prevOpenRows.includes(id)
                ? prevOpenRows.filter((rowId) => rowId !== id)
                : [...prevOpenRows, id]
        );
    };

    // Status color mapping
    const getStatusColor = (status) => {
        const statusColors = {
            'In Transit': 'warning',
            'Delivered': 'success',
            'Pending': 'secondary',
            'Scheduled': 'info',
            'Delayed': 'danger'
        };
        return statusColors[status] || 'primary';
    };

    // Delete Shipment Function
    const handleDeleteShipment = async () => {
        if (!selectedShipment) return;

        try {
            await axios.delete(`${ENDPOINT}/api/loads/${selectedShipment.id}`);
            onUpdateShipments(); // Refresh shipments list
            setShowDeleteConfirmModal(false);
        } catch (error) {
            console.error('Error deleting shipment:', error);
            // Handle error (e.g., show error toast)
        }
    };

    // Edit Shipment Function
    const handleEditShipment = async (updatedShipment) => {
        try {
            await axios.put(`${ENDPOINT}/api/loads/${selectedShipment.id}/s`, updatedShipment);
            onUpdateShipments(); // Refresh shipments list
            setShowEditModal(false);
        } catch (error) {
            console.error('Error updating shipment:', error);
            // Handle error (e.g., show error toast)
        }
    };

    // Detailed Shipment Details Modal
    const ShipmentDetailsModal = ({ shipment, show, onHide }) => (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Shipment Details - #{shipment.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="mb-3"><Truck className="mr-2" /> Shipment Overview</h5>
                        <div className="mb-2">
                            <strong><MapPin className="mr-2" size={16} /> Origin:</strong>
                            {shipment.load_origin_company_name}
                            <br />
                            <small className="text-muted">
                                {`${shipment.load_origin_address.street}, ${shipment.load_origin_address.city}, 
                ${shipment.load_origin_address.state} ${shipment.load_origin_address.zipcode}`}
                            </small>
                        </div>
                        <div className="mb-2">
                            <strong><Calendar className="mr-2" size={16} /> Scheduled Pickup:</strong>
                            {new Date(shipment.load_origin_window_start).toLocaleString()}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h5 className="mb-3"><Package className="mr-2" /> Load Details</h5>
                        <div className="mb-2">
                            <strong><DollarSign className="mr-2" size={16} /> Rate:</strong>
                            ${shipment.load_tender_details?.rate?.toFixed(2) || 'N/A'}
                        </div>
                        <div className="mb-2">
                            <strong>Weight:</strong> {shipment.load_weight} lbs
                        </div>
                        <div className="mb-2">
                            <strong>Status:</strong>
                            <Badge bg={getStatusColor(shipment.load_status)} className="ml-2">
                                {shipment.load_status}
                            </Badge>
                        </div>
                    </div>
                </div>
                <hr />
                <h5><User className="mr-2" /> Contacts</h5>
                <div className="row">
                    <div className="col-md-6">
                        <strong>Origin Contact:</strong> {shipment.load_origin_contact}
                    </div>
                    <div className="col-md-6">
                        <strong>Stop Contact:</strong> {shipment.load_stop_contact}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );

    return (
        <>
            <Card className="shadow-sm">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Shipment Management</h4>
                </Card.Header>
                <Card.Body>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Equipment</th>
                                <th>Route</th>
                                <th>Schedule</th>
                                <th>Status</th>
                                <th>Rate</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shipments.map((load) => (
                                <React.Fragment key={load.id}>
                                    <tr
                                        onClick={() => handleRowClick(load.id)}
                                        className="cursor-pointer"
                                    >
                                        <td>
                                            <strong>{load.load_equipment_type}</strong>
                                            <br />
                                            <small className="text-muted">ID: {load.id}</small>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <MapPin size={16} className="mr-2 text-muted" />&nbsp;
                                                <div>
                                                    {load.load_origin_company_name}
                                                    <small className="d-block text-muted">
                                                        → {load.load_destination_company_name}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Calendar size={16} className="mr-2 text-muted" />&nbsp;
                                            {new Date(load.load_origin_window_start).toLocaleDateString()}
                                        </td>
                                        <td>
                                            <Badge bg={getStatusColor(load.load_status)}>
                                                {load.load_status}
                                            </Badge>
                                        </td>
                                        <td>
                                            {/* <DollarSign size={16} className="mr-1 text-muted" /> */}
                                            ${load.load_tender_details?.rate?.toFixed(2) || 'N/A'}
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-secondary" size="sm">
                                                    <MoreVertical size={16} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedShipment(load);
                                                            setShowViewModal(true);
                                                        }}
                                                    >
                                                        <Eye size={16} className="mr-2" /> View Details
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedShipment(load);
                                                            setShowEditModal(true);
                                                        }}
                                                    >
                                                        <Edit size={16} className="mr-2" /> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item
                                                        className="text-danger"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setSelectedShipment(load);
                                                            setShowDeleteConfirmModal(true);
                                                        }}
                                                    >
                                                        <Trash2 size={16} className="mr-2" /> Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>

                                    {/* Expandable Row */}
                                    <tr>
                                        <td colSpan={12} className='g-4'>
                                            <Collapse in={openRows.includes(load.id)}>
                                                <Card className="border-0 ">
                                                    <Card.Body className="bg-light rounded">
                                                        <Row className="no-gutters">
                                                            <Col md={12} className="pr-md-2">
                                                                <h6 className="text-muted mb-3">Shipment Details</h6>
                                                                <div className="d-flex flex-column">
                                                                    <div className="mb-2 d-flex">
                                                                        <strong>Load Tender ID: </strong>
                                                                        <span>&nbsp;&nbsp;{load.load_tender_details?.tender_id || 'N/A'}</span>
                                                                    </div>
                                                                    <div className="mb-2 d-flex ">
                                                                        <strong>Origin Contact:</strong>
                                                                        <span>&nbsp;&nbsp;{load.load_origin_contact}</span>
                                                                    </div>
                                                                    <div className="mb-2 d-flex">
                                                                        <strong>Stop Contact:</strong>
                                                                        <span>&nbsp;&nbsp;{load.load_stop_contact}</span>
                                                                    </div>
                                                                </div>
                                                                <h6 className="text-muted mt-3 mb-3">Acceptance History</h6>
                                                                <div className="d-flex flex-column">
                                                                    {load.load_acceptance_history?.map((history, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="bg-white rounded p-2 mb-2"
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div className="font-weight-bold">{history.status}</div>
                                                                                <small className="text-muted">
                                                                                    {new Date(history.timestamp).toLocaleString()}
                                                                                </small>
                                                                            </div>
                                                                            <small className="text-muted">
                                                                                Updated by {history.updated_by}
                                                                            </small>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Collapse>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* Shipment Details Modal */}
            {selectedShipment && (
                <ShipmentDetailsModal
                    shipment={selectedShipment}
                    show={showViewModal}
                    onHide={() => setShowViewModal(false)}
                />
            )}

            {/* Delete Confirmation Modal */}
            <Modal
                show={showDeleteConfirmModal}
                onHide={() => setShowDeleteConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete shipment #{selectedShipment?.id}?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowDeleteConfirmModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteShipment}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Shipment Modal */}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Shipment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Basic Edit Form - Customize as per your specific shipment fields */}
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        const formData = new FormData(e.target);
                        const updatedShipment = {
                            load_equipment_type: formData.get('equipmentType'),
                            load_weight: formData.get('weight'),
                            load_status: formData.get('status')
                        };
                        handleEditShipment(updatedShipment);
                    }}>
                        <Form.Group>
                            <Form.Label>Equipment Type</Form.Label>
                            <Form.Control
                                name="equipmentType"
                                defaultValue={selectedShipment?.load_equipment_type}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Weight (lbs)</Form.Label>
                            <Form.Control
                                type="number"
                                name="weight"
                                defaultValue={selectedShipment?.load_weight}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                defaultValue={selectedShipment?.load_status}
                            >
                                <option>Pending</option>
                                <option>In Transit</option>
                                <option>Delivered</option>
                                <option>Delayed</option>
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3">
                            Update Shipment
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShipmentsTab;