export { default as headerlogo } from "../../assets/img/logo.svg";
export { default as Applogo } from "../../assets/img/logo2.png";
export { default as logo3 } from "../../assets/img/logo3.png";
export { default as lnEnglish } from "../../assets/img/flags/us.png";
export { default as lnFrench } from "../../assets/img/flags/fr.png";
export { default as lnSpanish } from "../../assets/img/flags/es.png";
export { default as lnGerman } from "../../assets/img/flags/de.png";
export { default as Avatar_01 } from "../../assets/img/profiles/avatar-01.jpg";
export { default as Avatar_02 } from "../../assets/img/profiles/avatar-02.jpg";
export { default as Avatar_03 } from "../../assets/img/profiles/avatar-03.jpg";
export { default as Avatar_04 } from "../../assets/img/profiles/avatar-04.jpg";
export { default as Avatar_05 } from "../../assets/img/profiles/avatar-05.jpg";
export { default as Avatar_06 } from "../../assets/img/profiles/avatar-06.jpg";
export { default as Avatar_07 } from "../../assets/img/profiles/avatar-07.jpg";
export { default as Avatar_08 } from "../../assets/img/profiles/avatar-08.jpg";
export { default as Avatar_09 } from "../../assets/img/profiles/avatar-09.jpg";
export { default as Avatar_10 } from "../../assets/img/profiles/avatar-10.jpg";
export { default as Avatar_11 } from "../../assets/img/profiles/avatar-11.jpg";
export { default as Avatar_12 } from "../../assets/img/profiles/avatar-12.jpg";
export { default as Avatar_13 } from "../../assets/img/profiles/avatar-13.jpg";
export { default as Avatar_14 } from "../../assets/img/profiles/avatar-14.jpg";
export { default as Avatar_15 } from "../../assets/img/profiles/avatar-15.jpg";
export { default as Avatar_16 } from "../../assets/img/profiles/avatar-16.jpg";
export { default as Avatar_17 } from "../../assets/img/profiles/avatar-17.jpg";
export { default as Avatar_18 } from "../../assets/img/profiles/avatar-18.jpg";
export { default as Avatar_19 } from "../../assets/img/profiles/avatar-19.jpg";
export { default as Avatar_20 } from "../../assets/img/profiles/avatar-20.jpg";
export { default as Avatar_21 } from "../../assets/img/profiles/avatar-21.jpg";
export { default as Avatar_22 } from "../../assets/img/profiles/avatar-22.jpg";
export { default as Avatar_24 } from "../../assets/img/profiles/avatar-24.jpg";
export { default as Avatar_25 } from "../../assets/img/profiles/avatar-25.jpg";
export { default as Avatar_26 } from "../../assets/img/profiles/avatar-26.jpg";
export { default as Avatar_28 } from "../../assets/img/profiles/avatar-28.jpg";
export { default as Avatar_29 } from "../../assets/img/profiles/avatar-29.jpg";

export { default as User } from "../../assets/img/user.jpg";
export { default as Img_01 } from "../../assets/img/img-01.jpg";
export { default as PlaceHolder } from "../../assets/img/placeholder.jpg";
export { default as Attachment } from "../../assets/img/attachment.png";
export { default as Video_Call } from "../../assets/img/video-call.jpg";
export { default as Veritical } from "../../assets/img/vertical.png";
export { default as Horizontal } from "../../assets/img/horizontal.png";
export { default as Two_col } from "../../assets/img/two-col.png";
export { default as Close } from "../../assets/img/close.png";
export { default as Boxed } from "../../assets/img/boxed.png";
export { default as dark } from "../../assets/img/dark.png";
export { default as compact } from "../../assets/img/compact.png";
export { default as small_hover } from "../../assets/img/small-hover.png";
export { default as Detached } from "../../assets/img/detached.png";
export { default as laptop } from "../../assets/img/laptop.png";
export { default as keyboard } from "../../assets/img/keyboard.png";
export { default as mouse } from "../../assets/img/mouse.png";
// export { default as key } from "../../assets/img/key.jpg";
export { default as img1 } from "../../assets/img/img1.jpg";
export { default as img2 } from "../../assets/img/img2.jpg";
export { default as img3 } from "../../assets/img/img3.jpg";
export { default as img4 } from "../../assets/img/img4.jpg";

export { default as eye } from "../../assets/img/icons/eye.svg";
export { default as icon01 } from "../../assets/img/icons/icon-01.svg";
export { default as icon02 } from "../../assets/img/icons/icon-02.svg";
export { default as icon03 } from "../../assets/img/icons/icon-04.svg";
export { default as icon04 } from "../../assets/img/icons/icon-04.svg";
export { default as icon05 } from "../../assets/img/icons/icon-05.svg";
export { default as edit_icon } from "../../assets/img/icons/edit.svg";
export { default as DownloadImg } from "../../assets/img/download.png";
export { default as image_01 } from "../../assets/img/img-1.jpg";
export { default as img_02 } from "../../assets/img/img-02.jpg";
export { default as img_03 } from "../../assets/img/img-03.jpg";
export { default as img_04 } from "../../assets/img/img-04.jpg";
export { default as img_05 } from "../../assets/img/img-05.jpg"; 
export { default as avatar19} from "../../assets/img/avatar/avatar-19.jpg"; 
export { default as clockin} from "../../assets/img/icons/clock-in.svg"; 
export { default as holidaycalendar} from "../../assets/img/icons/holiday-calendar.svg"; 
export { default as avatar1} from "../../assets/img/avatar/avatar-1.jpg"; 
export { default as avatar2} from "../../assets/img/avatar/avatar-2.jpg"; 
export { default as avatar20} from "../../assets/img/avatar/avatar-20.jpg"; 
export { default as avatar21} from "../../assets/img/avatar/avatar-21.jpg"; 
export { default as avatar16} from "../../assets/img/avatar/avatar-16.jpg"; 
export { default as avatar23} from "../../assets/img/avatar/avatar-23.jpg"; 
export { default as avatar18} from "../../assets/img/avatar/avatar-18.jpg"; 
export { default as avatar4} from "../../assets/img/avatar/avatar-4.jpg"; 
export { default as avatar6} from "../../assets/img/avatar/avatar-6.jpg"; 
export { default as avatar13} from "../../assets/img/avatar/avatar-13.jpg"; 
export { default as avatar26} from "../../assets/img/avatar/avatar-26.jpg"; 
export { default as avatar8 } from "../../assets/img/avatar/avatar-8.jpg"; 
export { default as employeeimg } from "../../assets/img/employee-img.png"; 
export { default as compamy04 } from "../../assets/img/company/company-04.svg"; 
export { default as compamy01 } from "../../assets/img/company/company-01.svg"; 
export { default as compamy02 } from "../../assets/img/company/company-02.svg"; 
export { default as compamy03 } from "../../assets/img/company/company-03.svg"; 
export { default as comingsoon } from "../../assets/img/coming-soon.png"; 
export { default as maintenancebg01 } from "../../assets/img/bg/maintenance-bg-01.png"; 
export { default as maintenancebg02 } from "../../assets/img/bg/maintenance-bg-02.png"; 
export { default as maintenancebg03 } from "../../assets/img/bg/maintenance-bg-03.png"; 
export { default as maintenancebg04 } from "../../assets/img/bg/maintenance-bg-04.png"; 
export { default as maintenancebg05 } from "../../assets/img/bg/maintenance-bg-05.png"; 
export { default as maintenance } from "../../assets/img/maintenance-img.png"; 
export { default as avatar27 } from "../../assets/img/avatar/avatar-27.jpg"; 
export { default as avatar28 } from "../../assets/img/avatar/avatar-28.jpg"; 
export { default as avatar22 } from "../../assets/img/avatar/avatar-22.jpg"; 
export { default as avatar24 } from "../../assets/img/avatar/avatar-24.jpg"; 
export { default as company_icon_01 } from "../../assets/img/icons/company-icon-01.svg"; 
export { default as company_icon_02 } from "../../assets/img/icons/company-icon-02.svg"; 
export { default as company_icon_03 } from "../../assets/img/icons/company-icon-03.svg"; 
export { default as company_icon_04 } from "../../assets/img/icons/company-icon-04.svg"; 
export { default as company_icon_05 } from "../../assets/img/icons/company-icon-05.svg"; 
export { default as company_icon_06 } from "../../assets/img/icons/company-icon-06.svg"; 
export { default as company_icon_07 } from "../../assets/img/icons/company-icon-07.svg"; 
export { default as company_icon_08 } from "../../assets/img/icons/company-icon-08.svg"; 
export { default as company_icon_09 } from "../../assets/img/icons/company-icon-09.svg"; 
export { default as company_icon_10 } from "../../assets/img/icons/company-icon-10.svg"; 
export { default as profileuploadimg } from "../../assets/img/icons/profile-upload-img.svg"; 
export { default as googleicon } from "../../assets/img/icons/google-icon.svg"; 
export { default as media35 } from "../../assets/img/media/media-35.jpg"; 
export { default as companyicon01 } from "../../assets/img/icons/company-icon-01.svg"; 
export { default as companyicon02 } from "../../assets/img/icons/company-icon-02.svg"; 
export { default as companyicon03 } from "../../assets/img/icons/company-icon-03.svg"; 
export { default as companyicon04 } from "../../assets/img/icons/company-icon-04.svg"; 
export { default as companyicon05 } from "../../assets/img/icons/company-icon-05.svg"; 
export { default as companyicon06 } from "../../assets/img/icons/company-icon-06.svg"; 
export { default as companyicon07 } from "../../assets/img/icons/company-icon-07.svg"; 
export { default as companyicon08 } from "../../assets/img/icons/company-icon-08.svg"; 
export { default as companyicon09 } from "../../assets/img/icons/company-icon-09.svg"; 
export { default as companyicon10 } from "../../assets/img/icons/company-icon-10.svg"; 
export { default as avatar25 } from "../../assets/img/avatar/avatar-25.jpg"; 
export { default as avatar7 } from "../../assets/img/avatar/avatar-7.jpg"; 
export { default as img_2 } from "../../assets/img/img-2.jpg"; 
export { default as img_3 } from "../../assets/img/img-3.jpg"; 
export { default as img_4 } from "../../assets/img/img-4.jpg"; 
export { default as img_5 } from "../../assets/img/img-5.jpg"; 
export { default as avatar3 } from "../../assets/img/avatar/avatar-3.jpg"; 
export { default as avatar11 } from "../../assets/img/avatar/avatar-11.jpg"; 
export { default as avatar5 } from "../../assets/img/avatar/avatar-5.jpg"; 
export { default as avatar9 } from "../../assets/img/avatar/avatar-9.jpg"; 
export { default as avatar10 } from "../../assets/img/avatar/avatar-10.jpg"; 
export { default as avatar12 } from "../../assets/img/avatar/avatar-12.jpg"; 
export { default as avatar14 } from "../../assets/img/avatar/avatar-14.jpg"; 
export { default as avatar15 } from "../../assets/img/avatar/avatar-15.jpg"; 
export { default as avatar17 } from "../../assets/img/avatar/avatar-17.jpg"; 
export { default as tagUserIcon } from "../../assets/img/icons/tag-user-icon.svg"; 
