import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "antd";
import DealsModal from '../../../components/modelpopup/DealsModal';
import { ENDPOINT } from '../../../Config/Constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Form,
  ListGroup, Accordion,
  Modal,
} from 'react-bootstrap';



const DriverListPage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [currentDriver, setCurrentDriver] = useState({})
  const [showViewDriverModal, setShowViewDriverModal] = useState(false);
  const [driverTypeFilter, setDriverTypeFilter] = useState('');
  const [hosStatusFilter, setHosStatusFilter] = useState('');
  const [showAddDriverModal, setShowAddDriverModal] = useState(false);
  const [newDriver, setNewDriver] = useState({
    driver_first_name: '',
    driver_last_name: '',
    driver_phone: '',
    driver_email: '',
    driver_license_state: '',
    driver_license_number: ''
  });
  const [isFullScreen, setFullScreen] = useState(false);
  const maximizeBtnRef = useRef(null);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${ENDPOINT}/api/drivers/`);
        const enrichedDrivers = response.data.map(driver => ({
          ...driver,
          fullName: `${driver.driver_first_name} ${driver.driver_last_name}`,
          image_url: `https://randomuser.me/api/portraits/men/${driver.driver_id}.jpg`,
          assignedLoads: Math.floor(Math.random() * 5),
          totalMileage: Math.floor(Math.random() * 3000),
          hosStatus: ['Compliant', 'Warning', 'Non-Compliant'][Math.floor(Math.random() * 3)],
          type: ['Full-Time', 'Part-Time', 'Contractor'][Math.floor(Math.random() * 3)]
        }));
        setDrivers(enrichedDrivers);
        console.log("DriversData: ", drivers)
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch drivers');
        setLoading(false);
      }
    };
    fetchDrivers();
  }, []);

  const handleDriverDetails = (driver) => {
    navigate('/driver-details', { state: { driverDetails: driver } });
  };

  const handleEditDriverClick = (driver) => {
    setCurrentDriver(driver);
    setNewDriver({
      driver_first_name: driver.firstName || driver.driver_first_name,
      driver_last_name: driver.lastName || driver.driver_last_name,
      driver_phone: driver.contactPhone || driver.driver_phone,
      driver_email: driver.contactEmail || driver.driver_email,
      driver_license_state: driver.driver_license_state || '',
      driver_license_number: driver.driver_license_number || '',
    });
    setShowAddDriverModal(true); // Show modal for editing
  };



  const handleDeleteDriver = async (id) => {
    try {
      await axios.delete(`${ENDPOINT}/api/drivers/${id}`);
      setDrivers(drivers.filter((driver) => driver.driver_id !== id));
    } catch (error) {
      console.error("Error deleting driver:", error);
    }
  };

  const handleViewDriver = (driver) => {
    setCurrentDriver({
      firstName: driver.driver_first_name || "N/A",
      lastName: driver.driver_last_name || "N/A",
      contactPhone: driver.driver_phone || "N/A",
      contactEmail: driver.driver_email || "N/A",
      address: driver.address || "N/A",
      qualifications: driver.qualifications || "N/A",
      location: driver.location || "N/A",
      activityLog: driver.activityLog || "N/A",
      hosLogs: driver.hosLogs || "N/A",
    });
    console.log((driver));

    setShowViewDriverModal(true); // Open the modal
  };


  const handleAddDriver = async (newDriver) => {
    try {
        const response = await axios.post(`${ENDPOINT}/api/drivers`, newDriver);
        setDrivers([...drivers, response.data]);
        resetNewDriverForm();
    } catch (err) {
        console.error('Failed to add driver', err);
    }
};

const resetNewDriverForm = () => {
    setNewDriver({
        driver_first_name: '',
        driver_last_name: '',
        driver_phone: '',
        driver_email: '',
        driver_license_state: '',
        driver_license_number: ''
    });
};

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        setFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          setFullScreen(false);
        }
      }
    };

    const cleanup = () => {
      if (isFullScreen && document.exitFullscreen) {
        document.exitFullscreen();
        setFullScreen(false);
      }
    };

    const maximizeBtn = maximizeBtnRef.current;
    maximizeBtn.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener and exit fullscreen on component unmount
    return () => {
      maximizeBtn.removeEventListener('click', handleClick);
      cleanup();
    };
  }, [isFullScreen]);


  const columns = [
    {
      title: "Profile Picture",
      dataIndex: "image_url",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          <div className="profile-pic-wrapper">
            <img
              src={record.image_url}
              alt={record.fullName}
              className="profile-pic"
              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            />
          </div>
        </Link>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Phone",
      dataIndex: "driver_phone",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "driver_email",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    // {
    //   title: "Address",
    //   dataIndex: "driver_address",
    //   render: (text, record) => (
    //     <Link to="/driver-details" state={{ customerData: record }}>
    //       {`${record.driver_address.street}, ${record.driver_address.city}, ${record.driver_address.state} ${record.driver_address.zip}`}
    //     </Link>
    //   ),
    // },
    {
      title: "License Number",
      dataIndex: "driver_license_number",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Qualifications",
      dataIndex: "driver_qualifications",
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {record.driver_qualifications.CDL ? 'CDL Certified' : 'Not Certified'}
        </Link>
      ),
    },
    {
      title: "Total Mileage",
      dataIndex: "totalMileage",
      sorter: (a, b) => a.totalMileage - b.totalMileage,
      render: (text, record) => (
        <Link to="/driver-details" state={{ driverDetails: record }}>
          {text}
        </Link>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <button
            className="btn btn-link action-icon dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleViewDriver(record)}
              >
                <i className="fa fa-eye m-r-5" /> View
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleEditDriverClick(record)}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleDeleteDriver(record.driver_id)}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </button>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  



  const optionsSortValue = [
    { value: 'sortAlphabet', label: 'Sort By Alphabet' },
    { value: 'ascending', label: 'Ascending' },
    { value: 'descending', label: 'Descending' },
    { value: 'recentlyViewed', label: 'Recently Viewed' },
    { value: 'recentlyAdded', label: 'Recently Added' }
  ];

  const optionSort = [
    { value: 'Germany', label: 'Germany' },
    { value: 'USA', label: 'USA' },
    { value: 'Canada', label: 'Canada' },
    { value: 'India', label: 'India' },
    { value: 'China', label: 'China' }
  ];

  const options = [
    { value: 'Conversation', label: 'Conversation' },
    { value: 'Pipeline', label: 'Pipeline' },
    { value: 'Won', label: 'Won' },
    { value: 'Lost', label: 'Lost' },
    { value: 'Follow Up', label: 'Follow Up' }
  ];

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">

          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h3 className="page-title">Drivers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item active">Drivers</li>
                </ul>
              </div>
              <div className="col-md-8 float-end ms-auto">
                <div className="d-flex title-head">
                  <div className="view-icons">
                    <Link to="#" className="grid-view btn btn-link"><i className="las la-redo-alt" /></Link>
                    <Link to="#" className="list-view btn btn-link" id="collapse-header" ref={maximizeBtnRef}><i className="las la-expand-arrows-alt" /></Link>
                    <Link to="#" className={fieldInputs ? "list-view btn btn-link active-filter" : "list-view btn btn-link"} id="filter_search" onClick={() => setFieldInputs(!fieldInputs)}><i className="las la-filter" /></Link>
                  </div>
                  <div className="form-sort">
                    <Link to="#" className="list-view btn btn-link" data-bs-toggle="modal" data-bs-target="#export"><i className="las la-file-export" />Export</Link>
                  </div>
                  <Link onClick={() => setShowAddDriverModal(true)} className="btn add-btn" ><i className="la la-plus-circle" /> Add Driver</Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}
          <div className="filter-filelds" id="filter_inputs" style={{ display: fieldInputs ? "block" : "none" }} >
            <div className="row filter-row">
              <div className="col-xl-2">
                <div
                  className={
                    focused || inputValue !== ""
                      ? "input-block form-focus focused"
                      : "input-block form-focus"
                  }
                >
                  <input
                    type="text"
                    className="form-control floating"
                    value={inputValue}
                    onFocus={handleLabelClick}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                  />
                  <label className="focus-label" onClick={handleLabelClick}>
                    Deal Name
                  </label>
                </div>
              </div>


              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus focused">
                  <DateRangePicker initialSettings={initialSettings}>
                    <input
                      className="form-control  date-range bookingrange"
                      type="text"
                    />
                  </DateRangePicker>
                  <label className="focus-label">From - To Date</label>

                </div>
              </div>

              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus">
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate1}
                      onChange={handleDateChange1}
                      className="form-control floating datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                  <label className="focus-label" style={{ top: "-20px" }}>Expected Date</label>
                </div>
              </div>

              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus select-focus">
                  <Select className="select floating" options={options} placeholder="--Select--" styles={customStyles}
                  />

                  <label className="focus-label">Stage</label>
                </div>
              </div>
              <div className="col-xl-2">
                <div className="input-block mb-3 form-focus select-focus">
                  <Select
                    options={optionSort}
                    placeholder="--Select--"
                    className="select floating"
                    isSearchable={false}
                    styles={customStyles}

                  />
                  <label className="focus-label">Location</label>
                </div>
              </div>
              <div className="col-xl-2">
                <Link to="#" className="btn btn-success w-100"> Search </Link>
              </div>
            </div>
          </div>
          <hr />
          {/* /Search Filter */}
          <div className="filter-section filter-bottom">
            <ul>
              <li>
                <div className="view-icons">
                  <Link to="/deals" className="list-view btn btn-link active"><i className="las la-list" /></Link>
                  <Link to="/deals-kanban" className="grid-view btn btn-link"><i className="las la-th" /></Link>
                </div>
              </li>
              <li>
                <div className="form-sort value-contain">
                  <i className="las la-sort-alpha-up-alt" />
                  <Select
                    options={optionsSortValue}
                    placeholder="Sort By Alphabet"
                    className="select w-100"
                    isSearchable={false}
                    styles={customStyles}

                  />
                </div>
              </li>
              <li>

                <div className={showFilter ? "form-sorts dropdown table-filter-show" : "form-sorts dropdown"} >
                  <Link to="#" className="dropdown-toggle" id="table-filter" onClick={() => setShowFilter(!showFilter)}><i className="las la-filter me-2" />Filter</Link>
                  <div className="filter-dropdown-menu" style={{ display: showFilter ? "block" : "none" }}>
                    <div className="filter-set-view">
                      <div className="filter-set-head">
                        <h4>Filter</h4>
                      </div>
                      <div className="accordion" id="accordionExample">
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link to="#" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Rating<i className="las la-angle-right" /></Link>
                          </div>
                          <div className="filter-set-contents accordion-collapse collapse show" id="collapseOne" data-bs-parent="#accordionExample">
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <span>5.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star" />
                                  <span>4.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <span>3.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <span>2.0</span>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="rating">
                                  <i className="fa fa-star filled" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <span>1.0</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link to="#" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Owner<i className="las la-angle-right" /></Link>
                          </div>
                          <div className="filter-set-contents accordion-collapse collapse" id="collapseTwo" data-bs-parent="#accordionExample">
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Hendry</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Guillory</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Jami</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Theresa</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Espinosa</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="filter-set-content">
                          <div className="filter-set-content-head">
                            <Link to="#" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Tags<i className="las la-angle-right" /></Link>
                          </div>
                          <div className="filter-set-contents accordion-collapse collapse" id="collapseThree" data-bs-parent="#accordionExample">
                            <ul>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" defaultChecked />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Promotion</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Rated</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Rejected</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Collab</h5>
                                </div>
                              </li>
                              <li>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input type="checkbox" />
                                    <span className="checkmarks" />
                                  </label>
                                </div>
                                <div className="collapse-inside-text">
                                  <h5>Calls</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="filter-reset-btns">
                        <Link to="#" className="btn btn-light">Reset</Link>
                        <Link to="#" className="btn btn-primary">Filter</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="search-set">
                  <div className="search-input">
                    <Link to="#" className="btn btn-searchset"><i className="las la-search" /></Link>
                    <div className="dataTables_filter">
                      <label> <input type="search" className="form-control form-control-sm" placeholder="Search" /></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={drivers}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Add Driver Modal */}
        <Modal centered show={showAddDriverModal} onHide={() => setShowAddDriverModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{currentDriver ? "Edit Driver" : "Create New Driver"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={newDriver.driver_first_name}
                      onChange={(e) =>
                        setNewDriver({ ...newDriver, driver_first_name: e.target.value })
                      }
                      placeholder="Enter first name"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={newDriver.driver_last_name}
                      onChange={(e) =>
                        setNewDriver({ ...newDriver, driver_last_name: e.target.value })
                      }
                      placeholder="Enter last name"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={newDriver.driver_address?.street || ""}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_address: { ...newDriver.driver_address, street: e.target.value },
                        })
                      }
                      placeholder="Street Address"
                    />
                    <Row className="mt-2">
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          value={newDriver.driver_address?.city || ""}
                          onChange={(e) =>
                            setNewDriver({
                              ...newDriver,
                              driver_address: { ...newDriver.driver_address, city: e.target.value },
                            })
                          }
                          placeholder="City"
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          value={newDriver.driver_address?.state || ""}
                          onChange={(e) =>
                            setNewDriver({
                              ...newDriver,
                              driver_address: { ...newDriver.driver_address, state: e.target.value },
                            })
                          }
                          placeholder="State"
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Control
                          type="text"
                          value={newDriver.driver_address?.zip || ""}
                          onChange={(e) =>
                            setNewDriver({
                              ...newDriver,
                              driver_address: { ...newDriver.driver_address, zip: e.target.value },
                            })
                          }
                          placeholder="ZIP Code"
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      value={newDriver.driver_phone}
                      onChange={(e) =>
                        setNewDriver({ ...newDriver, driver_phone: e.target.value })
                      }
                      placeholder="Enter phone number"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      value={newDriver.driver_email}
                      onChange={(e) =>
                        setNewDriver({ ...newDriver, driver_email: e.target.value })
                      }
                      placeholder="Enter email address"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>License State</Form.Label>
                    <Form.Control
                      type="text"
                      value={newDriver.driver_license_state?.state || ""}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_license_state: {
                            ...newDriver.driver_license_state,
                            state: e.target.value,
                          },
                        })
                      }
                      placeholder="Enter license state"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Issue Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={newDriver.driver_license_state?.issue_date || ""}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_license_state: {
                            ...newDriver.driver_license_state,
                            issue_date: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Expiry Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={newDriver.driver_license_state?.expiry_date || ""}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_license_state: {
                            ...newDriver.driver_license_state,
                            expiry_date: e.target.value,
                          },
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>License Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={newDriver.driver_license_number}
                      onChange={(e) =>
                        setNewDriver({ ...newDriver, driver_license_number: e.target.value })
                      }
                      placeholder="Enter license number"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Driver Qualifications</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="CDL"
                      checked={newDriver.driver_qualifications?.CDL || false}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_qualifications: {
                            ...newDriver.driver_qualifications,
                            CDL: e.target.checked,
                          },
                        })
                      }
                    />
                    <Form.Control
                      className="mt-2"
                      type="text"
                      value={newDriver.driver_qualifications?.endorsements?.join(", ") || ""}
                      onChange={(e) =>
                        setNewDriver({
                          ...newDriver,
                          driver_qualifications: {
                            ...newDriver.driver_qualifications,
                            endorsements: e.target.value.split(",").map((item) => item.trim()),
                          },
                        })
                      }
                      placeholder="Endorsements (e.g., Hazmat, Tanker)"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Driver Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newDriver.driver_notes}
                  onChange={(e) => setNewDriver({ ...newDriver, driver_notes: e.target.value })}
                  placeholder="Enter any additional notes"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddDriverModal(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleAddDriver(newDriver); // Add new driver
                setShowAddDriverModal(false);
                resetNewDriverForm();
              }}
            >
              Save Driver
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={showViewDriverModal} onHide={() => setShowViewDriverModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Driver Profile Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col md={6}>
                <h5 className=" mb-3">Personal Information</h5>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">First Name</Col>
                      <Col>{currentDriver?.firstName || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">Last Name</Col>
                      <Col>{currentDriver?.lastName || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">Contact Phone</Col>
                      <Col>{currentDriver?.contactPhone || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">Email</Col>
                      <Col>{currentDriver?.contactEmail || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={6}>
                <h5 className=" mb-3">Professional Details</h5>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">Location</Col>
                      <Col>{currentDriver?.location || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col xs={4} className="text-muted">Qualifications</Col>
                      <Col>{currentDriver?.qualifications || "N/A"}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>

            <Accordion defaultActiveKey="0" className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Additional Information</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <Row>
                            <Col xs={4} className="text-muted">Address</Col>
                            <Col>{currentDriver?.address || "N/A"}</Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Row>
                            <Col xs={4} className="text-muted">Activity Log</Col>
                            <Col>{currentDriver?.activityLog || "N/A"}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={6}>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <Row>
                            <Col xs={4} className="text-muted">HOS Logs</Col>
                            <Col>{currentDriver?.hosLogs || "N/A"}</Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={() => setShowViewDriverModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <DealsModal />
      </div>

    </>
  )
}

export default DriverListPage