import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    PlusCircle,
    Edit,
    Trash,
    Search,
    MapPin,
    ChevronDown,
    ChevronUp,
    Save,
    X
} from 'react-feather'
import { Badge, Button, Card, Col, Row, Table, Spinner, Alert } from 'react-bootstrap'
import AddEquipmentModal from './AddEquipmentModal'
import { ENDPOINT } from '../../../Config/Constants'
const EquipmentInventory = () => {
    // State Management
    const [equipmentList, setEquipmentList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [expandedEquipment, setExpandedEquipment] = useState(null)
    const [editingEquipment, setEditingEquipment] = useState(null)
    const [newEquipment, setNewEquipment] = useState(null)

    // Fetch Equipment Data
    useEffect(() => {
        const fetchEquipment = async () => {
            try {
                const response = await axios.get(`${ENDPOINT}/api/equipment`)
                setEquipmentList(response.data)
                setIsLoading(false)
            } catch (err) {
                setError('Failed to fetch equipment. Please try again later.')
                setIsLoading(false)
            }
        }

        fetchEquipment()
    }, [])

    const addEquipment = async (equipmentData) => {
        try {
            const response = await fetch(`${ENDPOINT}/api/equipment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(equipmentData),
            })

            if (response.ok) {
                const data = await response.json()
                // Update the equipment list with the newly added equipment
                setEquipmentList((prevList) => [...prevList, data])
                setNewEquipment(null) // Close the modal
            } else {
                const error = await response.json()
                alert('Error: ' + error.message)
            }
        } catch (error) {
            alert('Error: ' + error.message)
        }
    }

    // Search Handler
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    // Filtering Equipment
    const filteredEquipment = equipmentList.filter(
        (equipment) =>
            equipment.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            equipment.type.toLowerCase().includes(searchTerm.toLowerCase())
    )

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="page-title">Equipment Inventory</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/admin-dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Equipment Inventory</li>
                            </ul>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => setNewEquipment({})}
                                disabled={!!newEquipment}
                            >
                                <PlusCircle size={18} className="me-2" />
                                Add Equipment
                            </button>
                        </div>
                    </div>
                    <hr />

                    {/* Search Bar */}
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search equipment..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <button className="btn btn-outline-secondary" type="button">
                                    <Search size={15} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Equipment List */}
                    <div className="equipment-management">
                        <Table responsive hover bordered className="shadow-sm">
                            <thead className="table-light">
                                <tr>
                                    <th className="text-center">Details</th>
                                    <th>Equipment ID</th>
                                    <th>Type</th>
                                    <th>Location</th>
                                    <th>Capacity</th>
                                    <th>Last Maintenance</th>
                                    <th className="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEquipment.map((equipment) => (
                                    <React.Fragment key={equipment.id}>
                                        <tr
                                            className="cursor-pointer hover-highlight"
                                            onClick={() => setExpandedEquipment(
                                                expandedEquipment === equipment.id ? null : equipment.id
                                            )}
                                        >
                                            <td className="text-center">
                                                {expandedEquipment === equipment.id ? <ChevronUp /> : <ChevronDown />}
                                            </td>
                                            <td className="fw-bold">#{equipment.id}</td>
                                            <td>
                                                <Badge bg="secondary" pill>
                                                    {equipment.type}
                                                </Badge>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <MapPin size={16} className="me-2 text-primary" />
                                                    {`Lat: ${equipment.location.latitude}, Lon: ${equipment.location.longitude}`}
                                                </div>
                                            </td>
                                            <td>
                                                <Badge bg="info">
                                                    {`${equipment.capacity.weight} kg / ${equipment.capacity.volume} m³`}
                                                </Badge>
                                            </td>
                                            <td>
                                                {equipment.maintenanceHistory && equipment.maintenanceHistory.length > 0
                                                    ? equipment.maintenanceHistory[0].date
                                                    : 'No maintenance records'}
                                            </td>
                                            <td className="text-center">
                                                <div className="d-flex justify-content-center">
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        className="me-2"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            // Implement edit logic
                                                        }}
                                                    >
                                                        <Edit size={15} />
                                                    </Button>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            // Implement delete logic
                                                        }}
                                                    >
                                                        <Trash size={15} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>

                                        {/* Expanded Details Row */}
                                        {expandedEquipment === equipment.id && (
                                            <tr>
                                                <td colSpan="7" className="p-0">
                                                    <Card className="m-3">
                                                        <Card.Body>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <h6>Equipment Details</h6>
                                                                    <dl className="row">
                                                                        <dt className="col-sm-4">Created:</dt>
                                                                        <dd className="col-sm-8">
                                                                            {new Date(equipment.createdAt).toLocaleString()}
                                                                        </dd>

                                                                        <dt className="col-sm-4">Updated:</dt>
                                                                        <dd className="col-sm-8">
                                                                            {new Date(equipment.updatedAt).toLocaleString()}
                                                                        </dd>
                                                                    </dl>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <h6>Maintenance History</h6>
                                                                    <div className="maintenance-history" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                        {equipment.maintenanceHistory && equipment.maintenanceHistory.length > 0 ? (
                                                                            equipment.maintenanceHistory.map((record, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="border-bottom pb-2 mb-2"
                                                                                >
                                                                                    <small className="text-muted">{record.date}</small>
                                                                                    <p className="mb-0">{record.service}</p>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <p className="text-muted">No maintenance records</p>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    {/* No Equipment Found */}
                    {filteredEquipment.length === 0 && (
                        <div className="alert alert-warning text-center">
                            No equipment found matching your search criteria.
                        </div>
                    )}
                </div>
            </div>
            <AddEquipmentModal
                showModal={!!newEquipment}
                handleModalClose={() => setNewEquipment(null)}
                addEquipment={addEquipment}
            />
        </div>

    )
}

export default EquipmentInventory