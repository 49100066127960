import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../../../Config/Constants';

const AddShipment = ({ customerDetails }) => {
    const navigate = useNavigate()
    const location = useLocation();

    const customerID = location.state?.customer_id;
    console.log("NewShipments: " , customerID)

    const [shipmentData, setShipmentData] = useState({
        customer_id: customerID,
        load_origin_company_name: '',
        load_origin_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_origin_numbers: '',
        load_origin_contact: '',
        load_origin_window_start: '',
        load_origin_window_end: '',
        load_stop_company_name: '',
        load_stop_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_stop_numbers: '',
        load_stop_contact: '',
        load_stop_window_start: '',
        load_stop_window_end: '',
        load_destination_company_name: '',
        load_destination_address: {
            street: '',
            city: '',
            state: '',
            zipcode: ''
        },
        load_destination_numbers: '',
        load_destination_contact: '',
        load_destination_window_start: '',
        load_destination_window_end: '',
        load_equipment_type: '',
        load_weight: null,
        load_ltl: false,
        load_dimensions: {
            length: null,
            width: null,
            height: null
        },
        load_status: 'Scheduled',
        load_tender_details: null,
        load_acceptance_history: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        setShipmentData(prev => {
            if (nameParts.length === 2) {
                if (nameParts[0] === 'load_dimensions') {
                    return {
                        ...prev,
                        [nameParts[0]]: {
                            ...prev[nameParts[0]],
                            [nameParts[1]]: value === '' ? null : Number(value),
                        }
                    };
                }

                return {
                    ...prev,
                    [nameParts[0]]: {
                        ...prev[nameParts[0]],
                        [nameParts[1]]: value,
                    }
                };
            }

            switch (name) {
                case 'load_weight':
                    return { ...prev, [name]: value === '' ? null : Number(value) };
                case 'load_ltl':
                    return { ...prev, [name]: value === 'true' };
                default:
                    return { ...prev, [name]: value };
            }
        });
    };

    const handleAddShipment = async (e) => {
        e.preventDefault();

        // Validate required fields
        const requiredFields = [
            'load_origin_company_name',
            'load_origin_address.street',
            'load_origin_address.city',
            'load_destination_company_name',
            'load_destination_address.street',
            'load_destination_address.city',
            'load_equipment_type'
        ];


        const formatDate = (dateString) => {
            if (!dateString) return null;
            try {
                // Convert datetime-local to ISO 8601 format
                return new Date(dateString).toISOString();
            } catch {
                return null;
            }
        };

        const isValid = requiredFields.every(field => {
            const [parent, child] = field.split('.');
            return child
                ? shipmentData[parent][child] !== ''
                : shipmentData[parent] !== '';
        });

        if (!isValid) {
            alert('Please fill in all required fields');
            return;
        }

        // Prepare payload with proper formatting
        const payload = {
            ...shipmentData,
            load_weight: shipmentData.load_weight || 0,
            load_dimensions: {
                length: shipmentData.load_dimensions.length || 0,
                width: shipmentData.load_dimensions.width || 0,
                height: shipmentData.load_dimensions.height || 0
            },
            load_tender_details: shipmentData.load_tender_details || undefined,
            load_acceptance_history: shipmentData.load_acceptance_history || undefined,
            load_origin_window_start: formatDate(shipmentData.load_origin_window_start),
            load_origin_window_end: formatDate(shipmentData.load_origin_window_end),
            load_stop_window_start: formatDate(shipmentData.load_stop_window_start),
            load_stop_window_end: formatDate(shipmentData.load_stop_window_end),
            load_destination_window_start: formatDate(shipmentData.load_destination_window_start),
            load_destination_window_end: formatDate(shipmentData.load_destination_window_end),
        };

        try {
            const response = await fetch(`${ENDPOINT}/api/loads`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to create shipment: ${errorText}`);
            }

            const data = await response.json();
            navigate(-1);
            // console.log('Shipment created successfully:', data);
        } catch (error) {
            console.error('Error creating shipment:', error);
            alert(error.message);
        }
    };

    const renderAddressSection = (type, displayName) => (
        <>
            <Card.Subtitle className="mb-3 mt-3 fs-20">{displayName} Details</Card.Subtitle>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_company_name`}
                            value={shipmentData[`${type}_company_name`]}
                            onChange={handleInputChange}
                            placeholder={`Enter ${displayName} company name`}
                            required={type !== 'load_stop'}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Contact</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_contact`}
                            value={shipmentData[`${type}_contact`]}
                            onChange={handleInputChange}
                            placeholder="Name, email, phone"
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_address.street`}
                            value={shipmentData[`${type}_address`].street}
                            onChange={handleInputChange}
                            placeholder={`Enter ${displayName} street`}
                            required={type !== 'load_stop'}
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_address.city`}
                            value={shipmentData[`${type}_address`].city}
                            onChange={handleInputChange}
                            placeholder={`Enter ${displayName} city`}
                            required={type !== 'load_stop'}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_address.state`}
                            value={shipmentData[`${type}_address`].state}
                            onChange={handleInputChange}
                            placeholder={`Enter ${displayName} state`}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Zipcode</Form.Label>
                        <Form.Control
                            type="text"
                            name={`${type}_address.zipcode`}
                            value={shipmentData[`${type}_address`].zipcode}
                            onChange={handleInputChange}
                            placeholder={`Enter ${displayName} zipcode`}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Window Start</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name={`${type}_window_start`}
                            value={shipmentData[`${type}_window_start`]}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );

    return (
        <div>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <Container className="my-4">
                        <Card>
                            {/* <Card.Header as="h2" className="bg-primary text-white">Create New Shipment</Card.Header> */}
                            <Card.Body>
                                <Form onSubmit={handleAddShipment}>
                                    {renderAddressSection('load_origin', 'Pickup')}
                                    {renderAddressSection('load_stop', 'Stop')}
                                    {renderAddressSection('load_destination', 'Delivery')}

                                    <Card.Subtitle className="mb-3 mt-3 fs-20 ">Shipment Details</Card.Subtitle>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Origin Numbers</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="load_origin_numbers"
                                                    value={shipmentData.load_origin_numbers}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter origin numbers"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Stop Numbers</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="load_stop_numbers"
                                                    value={shipmentData.load_stop_numbers}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter stop numbers"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Destination Numbers</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="load_destination_numbers"
                                                    value={shipmentData.load_destination_numbers}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter destination numbers"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Equipment Type</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="load_equipment_type"
                                                    value={shipmentData.load_equipment_type}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter equipment type"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Weight</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="load_weight"
                                                    value={shipmentData.load_weight}
                                                    onChange={(e) => handleInputChange(e)}
                                                    placeholder="Enter weight"
                                                    required
                                                />
                                            </Form.Group>

                                        </Col> */}
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select
                                                    name="load_status"
                                                    value={shipmentData.load_status}
                                                    onChange={handleInputChange}
                                                >
                                                    <option>Scheduled</option>
                                                    <option>In Transit</option>
                                                    <option>Delivered</option>
                                                    <option>Cancelled</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Length</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="load_dimensions.length"
                                                    value={shipmentData.load_dimensions.length}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter length"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Width</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="load_dimensions.width"
                                                    value={shipmentData.load_dimensions.width}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter width"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Height</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="load_dimensions.height"
                                                    value={shipmentData.load_dimensions.height}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter height"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button variant="primary" type="submit" className="w-100 mt-3">
                                        Create Shipment
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </div>

    );
};

export default AddShipment;