import React from 'react';
import {
 Row, Col, Card, Table,
  Badge,ListGroup,
} from 'react-bootstrap';

const BillingTab = ({ customerDetails }) => {
    const totalBilled = customerDetails.totalPayments || 0;

    return (
      <>
        <Row className="g-2 ">
          <Col md={4}>
            <Card className="shadow-sm">
              <Card.Body>
                <h6 className="text-muted mb-2">Total Billed Amount</h6>
                <h3 className="mb-0">${totalBilled.toLocaleString()}</h3>
                <small className="text-success">All time total</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow-sm">
              <Card.Body>
                <h6 className="text-muted mb-2">Company Type</h6>
                <h3 className="mb-0">{customerDetails.type}</h3>
                <small className="text-info">Business Classification</small>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="shadow-sm">
              <Card.Body>
                <h6 className="text-muted mb-2">Payment Terms</h6>
                <h3 className="mb-0">{customerDetails.paymentTerms}</h3>
                <small className="text-primary">Current terms</small>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="g-4">
          <Col md={6}>
            <Card className="shadow-sm">
              <Card.Header>
                <h5 className="mb-0">Billing Information</h5>
              </Card.Header>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Company</span>
                    <strong>{customerDetails.company}</strong>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Billing Contact</span>
                    <strong>{customerDetails.billingContactName}</strong>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Email</span>
                    <strong>{customerDetails.billingContactEmail}</strong>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Phone</span>
                    <strong>{customerDetails.billingContactPhone}</strong>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Tax ID</span>
                    <strong>{customerDetails.taxId}</strong>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex justify-content-between">
                    <span className="text-muted">Contract Status</span>
                    <strong>{customerDetails.contractStatus}</strong>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="shadow-sm">
              <Card.Header>
                <h5 className="mb-0">Recent Invoices</h5>
              </Card.Header>
              <Table hover responsive className="mb-0">
                <thead>
                  <tr>
                    <th>Invoice #</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {customerDetails.paymentHistory && customerDetails.paymentHistory.map((payment, index) => (
                    <tr key={index}>
                      <td>{payment.invoice_number}</td>
                      <td>{new Date(payment.date).toLocaleDateString()}</td>
                      <td>${payment.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      <td>
                        <Badge
                          bg={payment.status === 'Paid' ? 'success' : 'warning'}
                          className="text-white"
                        >
                          {payment.status}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    );
  };


  export default BillingTab